import i18n from "i18next";
import moment from 'moment';
import 'moment/locale/da';
import 'moment/locale/nb';
import 'moment/locale/sv';

import Languages from '../Config/languages';
import { BETA_SITE_URL } from '../Config';

var LocalesUpdated = [];

export function arrayMove(x, from, to) {
    x = x.slice();
    x.splice((to < 0 ? x.length + to : to), 0, x.splice(from, 1)[0]);
    return x;
}

export const CalculateDynamicRepsFromWeight = ({ Progress = { Current: 0 }, SetType, Weight }) => {
    Weight = +Weight;

    // Weight is a percentage
        if (SetType === 'Template') {
            if (Weight >= 100) return 1;

            return Math.ceil(Math.round(30 * ((1 / (Weight / 100)) - 1) * 100) / 100); // Gives a value such as 85.71
        }

    // Weight is actual weight
       return Math.floor(30 * ((Progress.Current / Weight) - 1));
}

export const CalculateDynamicWeightFromReps = ({ Progress = { Current: 0 }, Reps, SetType }) => {
    Reps = +Reps;

    if (SetType === 'Template') {
        if (Reps === 0 || Reps === 1) return 100.00;

        return Math.round(((1 / ((1 + (Reps / 30)))) * 100) * 100) / 100; // Gives a value such as 85.71
    }

    return Math.floor(Progress.Current / (1 + (Reps / 30)));
}

export const ConvertEmojiToUnicode = Emoji => {
    return Emoji.codePointAt(0).toString(16);
}

export const ConvertUnicodeToEmoji = Unicode => {
    return String.fromCodePoint(parseInt(Unicode, 16));
}

export const CreateUploadToPresignedUrlPromise = ({ ContentType, CustomLabelName, File, PresignedFormData, UploadFunction }) => {
    return new Promise((resolve, reject) => UploadFunction({ ContentType, CustomLabelName, File, PresignedFormData }).then(resolve).catch(reject));
}

export function DateSort(date1, date2) {
    // This is a comparison function that will result in dates being sorted in
    // ASCENDING order. As you can see, JavaScript's native comparison operators
    // can be used to compare dates. This was news to me.
    if (date1 > date2) return 1;
    if (date1 < date2) return -1;
    return 0;
}

export function DynamicSort({ IsInt, SortCol, SortDir }) {
    var sortOrder = 1;
    if (SortCol[0] === "-") {
        sortOrder = -1;
        SortCol = SortCol.substr(1);
    }

    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */

        var result;

        var Item_A = a[SortCol] ? !IsInt ? a[SortCol].toString().toLowerCase() : a[SortCol] : '';
        var Item_B = b[SortCol] ? !IsInt ? b[SortCol].toString().toLowerCase() : b[SortCol] : '';

        if (SortDir === 'ASC') result = (Item_A < Item_B) ? -1 : (Item_A > Item_B) ? 1 : 0;
        else if (SortDir === 'DESC') result = (Item_A < Item_B) ? 1 : (Item_A > Item_B) ? -1 : 0;
        return result * sortOrder;
    }
}

export const emailValidator = Email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    return re.test(String(Email).toLowerCase());
}

export function getBorderRadiusFromHeight(Size) {
    switch (Size) {
        case "small":
            return '15px';
        case "medium":
            return '20px';
        case "large":
            return '25px';
        case "extra-large":
            return '33px';
        default:
            return '15px';
    }
}

/**
 * 
 * @param {string} fromDate 
 * @param {string} toDate 
 * @param {string} dateDifferenceUnits // Can be days, hours, minutes or seconds
 * @returns 
 */
export function getDateDifference(fromDate, toDate, dateDifferenceUnits) {
  const FromDateMoment = moment(fromDate);
  const ToDateMoment = toDate ? moment(toDate) : moment();
  const difference = ToDateMoment.diff(FromDateMoment, dateDifferenceUnits);
  return difference;
}

export function getFileExtension(FileName) {
    return /(?:\.([^.]+))?$/.exec(FileName.toLowerCase())[1];
}

export function getHeight(Size) {
    switch (Size) {
        case "small":
            return '30px';
        case "medium":
            return '40px';
        case "large":
            return '50px';
        case "extra-large":
            return '65px';
        default:
            return '30px';
    }
}

export function getNewDays(DayIds, Days, TemplateDays, ArrayOfIdsName, ArrayOfObjectsName) {
    if (!ArrayOfIdsName) ArrayOfIdsName = 'DishIds';
    if (!ArrayOfObjectsName) ArrayOfObjectsName = 'Dishes';
    
    var NewDays = [];
    var NewDayIds = [];

    for (var i = 0; i < TemplateDays; i++) {
        var DayId = `Day-${i + 1}`;
        var ExistingDayIndex = DayIds.indexOf(DayId);

        NewDays = [ ...NewDays, ExistingDayIndex > -1 ? Days[ExistingDayIndex] : { DayId, [ArrayOfIdsName]: [], [ArrayOfObjectsName]: [] } ];
        NewDayIds = [ ...NewDayIds, DayId ];
    }

    return({ NewDays, NewDayIds });
}

export const GetNotificationUrl = ({ notificationType, referenceData = {}, userId }) => {
    referenceData = referenceData || {};
    var { clientId, contentType, exerciseId, goalType, id, mealId, workoutId } = referenceData;

    switch (notificationType) {
        case 'MealAdded':
        case 'DailyCaloriesExceeded':
        case 'ClientMealsNotCompleted':
            return `/client/${userId}?tab=meals`;

        case 'StatusAdded':
        case 'TaskAdded':
        case 'ClientStatusCompleted':
        case 'ClientTaskCompleted':
            return `/client/${clientId}?tab=tasks`;

        case 'BodyweightUpdate':
            return `/client/${clientId}/bodyweight`;

        case 'ContentSharingRequestReceived':
        case 'ContentSharingRequestStatusUpdated':
        case 'ContentSharingRequestUnshared':
            var LibraryLink = '';
            if (contentType === 'WorkoutTemplates') LibraryLink = 'workout-templates';
            else if (contentType === 'Exercises') LibraryLink = 'exercises';
            else if (contentType === 'MealTemplates') LibraryLink = 'meal-templates';
            else if (contentType === 'TrainingPrograms') LibraryLink = 'training-programs';

            return `/library?tab=${LibraryLink}`;

        case 'WorkoutAdded':
        case 'DailyWorkoutReminder':
        case 'ClientWorkoutNotCompleted':
        case 'TeamRequestedClient':
            return `/client/${userId}?tab=workouts`;

        case 'WorkoutCopiedMoved':
            return `/client/${clientId}?tab=workouts`;

        case 'OrgMemberUpdatedStatus':
        case 'OrgInvitedTrainer':
        case 'OrgMemberLeftOrg':
            return `/coaches`;

        case 'VideoProcessed':
            return `/library/exercise/${exerciseId}`;

        case 'ProgressGoalCompleted':
            var NotificationUrl = `/client/${clientId}`;

            if (goalType === 'strength') {
                NotificationUrl = `${NotificationUrl}/exercise/${workoutId}`;
            }
            else {
                NotificationUrl = `${NotificationUrl}/${goalType}`;
            
                if (goalType === 'circumference') {
                    NotificationUrl = `${NotificationUrl}/${id}`;
                }
            }

            return NotificationUrl;

        case 'ClientUpdatedTrainerStatus':
            return `/client/${clientId}`;
        
        case 'TrainerRequestedClient':
            return '/clients';

        case 'TrainerAddedContract':
        case 'ClientSignedContract':
        case 'ClientPaidInvoice':
        case 'ClientMissedInvoice':
        case 'ClientCancelledContract':
        case 'ClientInvoiceDue':
        case 'ClientInvoiceOverdue':
            return `/sales?tab=contracts`;

        case 'ClientBirthday':
            return `/client/${clientId}`;

        case 'ClientNoteAdded':
        case 'TrainerNoteAdded':
            return `/workout/${workoutId}`;

        case 'NewExerciseRecord':
            return `/workout/${workoutId}`;

        case 'ClientSessionCompleted':
            return `/workout/${workoutId}`;

        case 'ClientMealCompleted':
            return `/meal/${mealId}`;

        case "BookingClientCancelled":
        case "BookingClientApproved":
        case "BookingClientRejected":
        case "BookingClientCreated":
        case "BookingCoachCreated":
        case "BookingCoachRejected":
        case "BookingCoachApproved":
        case "BookingCoachCancelled":
            return `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=booking?tab=booking`;

        default:
            return '/clients';
    }
}

export function GetSetUnits(ExerciseType, Units) {
    var SetUnits = '';

    if (ExerciseType === 1) { SetUnits = 'Reps'; }
    else if (ExerciseType === 2) { SetUnits = 'Time'; }
    else if (ExerciseType === 3) {
        if (Units === 'imperial') { SetUnits = 'Yards'; }
        else { SetUnits = 'Meters'; }
    } else if (ExerciseType === 4) {
        if (Units === 'imperial') { SetUnits = 'lbs'; }
        else { SetUnits = 'kg'; }
    } else if (ExerciseType === 5) {
        if (Units === 'imperial') { SetUnits = 'lbs'; }
        else { SetUnits = 'kg'; }
    }

    return SetUnits;
}

export const GetTimeZoneOffsetInHHMM = () => {
    var timezone_offset_min = new Date().getTimezoneOffset(),
	offset_hrs = parseInt(Math.abs(timezone_offset_min / 60)),
	offset_min = Math.abs(timezone_offset_min % 60),
	timezone_standard;

    if (offset_hrs < 10) offset_hrs = '0' + offset_hrs;
    if (offset_min < 10) offset_min = '0' + offset_min;

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
        if (timezone_offset_min < 0) timezone_standard = '+' + offset_hrs + ':' + offset_min;
        else if (timezone_offset_min > 0) timezone_standard = '-' + offset_hrs + ':' + offset_min;
        else if (timezone_offset_min === 0) timezone_standard = '+00:00';

    return timezone_standard;
}

export const GetTranslatedFilterName = ({ IsAbel, FilterName }, t) => {
    var TranslatedFilterName = FilterName;
    
    if (IsAbel) {
        if (FilterName === 'Equipment') TranslatedFilterName = t('_equipment');
        else if (FilterName === 'Injuries') TranslatedFilterName = t('WorkoutPlan_injuries');
        else if (FilterName === 'Joints') TranslatedFilterName = t('_target_avoid_joints');
        else if (FilterName === 'MadeBy') TranslatedFilterName = t('_made_by');
        else if (FilterName === 'Movement') TranslatedFilterName = t('_movement');
        else if (FilterName === 'Muscles') TranslatedFilterName = t('_target_avoid_muscles');
        else if (FilterName === 'Plane') TranslatedFilterName = t('_plane');
        else if (FilterName === 'RepRange') TranslatedFilterName = t('rep_range');
        else if (FilterName === 'StartPosition') TranslatedFilterName = t('_startposition');
        else if (FilterName === 'Tags') TranslatedFilterName = t('_tags');
        else if (FilterName === 'TrainingFocus') TranslatedFilterName = t('_trainingfocus');
        else if (FilterName === 'TrainingForm') TranslatedFilterName = t('_trainingform');
        else if (FilterName === 'WorkoutType') TranslatedFilterName = t('_workout_type');
    }

    return TranslatedFilterName;
}

export const IsOnlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

export const PaginateArray = ({ ArrayList, PageNo, PageSize }) => {
    return ArrayList.slice((PageNo - 1) * PageSize, PageNo * PageSize);
}

export const PageRange = ({ PageNo, TotalPages }) => {
    var StartPageNo = PageNo - 2;
    var EndPageNo = PageNo + 2;
    
    if (EndPageNo > TotalPages) {
        StartPageNo -= (EndPageNo - TotalPages);
        EndPageNo = TotalPages;
    }
    
    if (StartPageNo <= 0) {
        EndPageNo += ((StartPageNo - 1) * -1);
        StartPageNo = 1;
    }
    
    EndPageNo = EndPageNo > TotalPages ? TotalPages : EndPageNo;
    
    return { EndPageNo, StartPageNo };
}

export const PriceCalculator = ({ DiscountAmount, DiscountPercent, DiscountType, Price, Quantity }) => {
    var TotalPrice = Price * Quantity;

    if (DiscountType === 'Amount') TotalPrice -= DiscountAmount;
    else if (DiscountType === 'Percentage') TotalPrice = TotalPrice * (1 - (DiscountPercent / 100));

    return TotalPrice;
}

export function regex_HasWord(StringToMatch, StringToTest) {
	var re = new RegExp(StringToMatch);

	return re.test(StringToTest);
}

export const RenderCurrencyText = ({ CurrencySign, Price, SignUsed }) => {
    var CurrencyText = '0';

    // Add Decimals Only If Not Integer
        if (Price % Math.floor(Price) !== 0) Price = parseFloat(Price).toFixed(2);
    
    if (SignUsed === 'After') CurrencyText = `${Price} ${CurrencySign}`;
    else if (SignUsed === 'Before') CurrencyText = `${CurrencySign}${Price}`;

    return CurrencyText;
}

export function SecondsToMinutesAndSeconds(seconds) {
    var Minutes = Math.floor(seconds / 60);

	var Seconds = seconds % 60;

    return { Minutes, Seconds };
}

export function SecondsToTime(seconds) {
	var Minutes = Math.floor(seconds / 60);
	if (Minutes < 10) { Minutes = '0' + Minutes; }

	var Seconds = seconds % 60;
	if (Seconds < 10) { Seconds = '0' + Seconds; }

    if (Minutes > 60) {
        // 135:45
        var Hours = Math.floor(Minutes / 60);
        if (Hours < 10) { Hours = '0' + Hours; }

        Minutes = Minutes - (Hours * 60);
        if (Minutes < 10) { Minutes = '0' + Minutes; }

        return Hours + ':' + Minutes + ':' + Seconds;
    }

	return Minutes + ':' + Seconds;
}

export const SetLanguage = LanguageId => {
    return new Promise((resolve, reject) => {
        LanguageId = +LanguageId;
    
        var { i18nLocale, longDateFormat, momentLocale, relativeTime } = Languages[`${LanguageId}`];
    
        moment.locale(momentLocale);
        i18n.changeLanguage(i18nLocale);
    
        moment.relativeTimeThreshold('ss', 0);
    
        if (LocalesUpdated.indexOf(+LanguageId) === -1) {
            LocalesUpdated.push(+LanguageId);
    
            moment.updateLocale(momentLocale, { longDateFormat, relativeTime });
        }

        resolve();
    })
}

export function SortValues(key, order = 'asc') {
    return function innerSort(a, b) {
        // property doesn't exist on either object
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
  
        const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
  
        let comparison = 0;
        if (varA > varB) comparison = 1;
        else if (varA < varB) comparison = -1;

        return (order === 'desc') ? (comparison * -1) : comparison;
    };
}

export function SplitMoney({ NumSplits, TotalPrice }) {
    TotalPrice = +TotalPrice;

    // Split into equal amounts, except for last one
        var TotalAddedPrice = 0;
        var Splits = [];

        for (var i = 0; i < NumSplits - 1; i++) {
            var SplitPrice = (TotalPrice / NumSplits).toFixed(2);
            TotalAddedPrice += +SplitPrice;
            Splits.push(SplitPrice);
        }

    // Adjust last split to take remaining of TotalPrice
        Splits.push((TotalPrice - TotalAddedPrice).toFixed(2));

    return Splits;
}

export function TimeToSeconds(TimeString) {
    var SplitArray = TimeString.split(':');

    // Has Hours (hh:mm:ss)
        if (SplitArray.length > 2) return (+(TimeString.split(':')[0]) * 60 * 60) + (+(TimeString.split(':')[1]) * 60) + (+(TimeString.split(':')[2]));

    // Just Minutes & Seconds (mm:ss)
        return (+(TimeString.split(':')[0]) * 60) + (+(TimeString.split(':')[1]));
}

export function validateFile({ AllowedTypes, FileExtension, FileType }) {
    AllowedTypes = AllowedTypes || (FileType.toLowerCase() === 'images' ? [ 'jpg', 'jpeg', 'png' ] : [ 'mov', 'mp4' ]);

    return FileType === 'Images' ? AllowedTypes.includes(FileExtension) : FileType === 'Videos' ? AllowedTypes.includes(FileExtension) : false;
}
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearClientDetails, ClearDishDetail, DeleteMealDish, DeleteDishDetailFood, GetDishDetail, UpdateDishCompletionStatus, UpdateDishDetailFood, UpdateSelectedTab } from '../../Services/Actions';
import history from '../../history';

import { ColumnMargin, DeleteIcon, DishImageContainer, IngredientContainer, IngredientListContainer, IngredientNameStyled, IngredientOptionsContainer, IngredientQuantityStyledInput, IngredientsContainer, IngredientUnit, InstructionContainer, InstructionsContainer, ResponsiveColumns, Section1, StyledBackgroundImage, StyledButtonGroup } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Page from '../../Components/Page';
import Spacer from '../../Components/Spacer';

import AddFood from '../../Modals/AddFood';
import FoodUnits from '../../Modals/FoodUnits';
import Info from '../../Modals/Info';
import NutritionFacts from '../../Modals/NutritionFacts';

import DeleteX from '../../Assets/Icons/Delete_X.png';
import Plus from '../../Assets/Icons/Plus.png';

class DishDetail extends React.Component {
    _isMounted = false;

    state = {
        DishDetail: {},
        ExcludeFoodIds: [],
        FoodToDelete: {},
        FoodUnitsData: {},
        IsLoggedFood: false,
        Loading: true,
        ShowAddFoodModal: false,
        ShowDeleteDishModal: false,
        ShowDeleteFoodModal: false,
        ShowFoodUnitsModal: false,
        ShowNutritionModal: false,
        UpdatingDish: false
    };

    componentDidMount() {
        this._isMounted = true;

        var { MealPlanUId } = this.props.match.params;

        this.props.GetDishDetail({ uId: MealPlanUId }).then(() => {
            document.title = `ABEL - ${this.props.ClientDetails.FirstName && this.props.ClientDetails.LastName ? `${this.props.ClientDetails.FirstName} ${this.props.ClientDetails.LastName}` : this.props.ClientDetails.Email} - ${this.props.DishDetail.DishName}`;

            if (this._isMounted) {
                var ExcludeFoodIds = this.props.DishDetail.dataFoods.map(({ FoodId }) => Number(FoodId));
                this.setState({ DishDetail: this.props.DishDetail, ExcludeFoodIds, IsLoggedFood: Number(this.props.DishDetail.DishId) ? false : true, Loading: false, UpdatingDish: false });
            }
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        document.title = 'ABEL Admin';

        this.props.ClearClientDetails();
        this.props.ClearDishDetail();
    }

    onAddFood = ({ FoodId, UnitId }) => {
        var { ClientId } = this.props.ClientDetails;
        var { MealPlanUId } = this.props.match.params;
        var { DishId } = this.state.DishDetail;

        this.setState({ ShowAddFoodModal: false, UpdatingDish: true }, () => this.props.UpdateDishDetailFood({ ClientId, DishId, FoodId, MealPlanUId, Quantity: 1, UnitId }).then(() => {
            this.onRefreshDishDetail();
        }));
    }

    onBlurFoodQuantity = FoodIndex => {
        var UpdateFoodQuantity_DishIngredients = this.state.DishDetail;

        if (+UpdateFoodQuantity_DishIngredients.dataFoods[FoodIndex].Quantity === 0) {
            UpdateFoodQuantity_DishIngredients.dataFoods[FoodIndex].Quantity = 0;
            this.setState({ DishDetail: UpdateFoodQuantity_DishIngredients }, () => this.onUpdateFoodDetail(FoodIndex));
        }
        else this.onUpdateFoodDetail(FoodIndex);
    }

    onChangeFoodQuantity = (event, FoodIndex) => {
        var onChangeFoodQuantity = { ...this.state.DishDetail };
        onChangeFoodQuantity.dataFoods[FoodIndex] = { ...onChangeFoodQuantity.dataFoods[FoodIndex], Quantity: event.target.value };

        this.setState({ DishDetail: onChangeFoodQuantity });
    }

    onDeleteDish = () => {
        this.setState({ ShowDeleteDishModal: false }, () => {
            this.props.DeleteMealDish(this.props.match.params.MealPlanUId);

            history.push({ pathname: `/client/${this.props.ClientDetails.ClientId}`, state: { SelectedTab: 'Meals', MealDate: this.state.DishDetail.MealDate } });
        });
    }

    onDeleteFood = ({ FoodId, FoodIndex }) => {
        var { ClientId } = this.props.ClientDetails;
        var { MealPlanUId } = this.props.match.params;
        // var { DishDetail: { DishId }, FoodToDelete: { FoodId, FoodIndex } } = this.state;
        var { DishDetail: { DishId } } = this.state;

        var DishDetail = { ...this.state.DishDetail };
        DishDetail.dataFoods = [ ...DishDetail.dataFoods.slice(0, FoodIndex), ...DishDetail.dataFoods.slice(FoodIndex + 1) ];

        var ExcludeFoodIds_Index = this.state.ExcludeFoodIds.indexOf(Number(FoodId));
        var ExcludeFoodIds = [ ...this.state.ExcludeFoodIds.slice(0, ExcludeFoodIds_Index), ...this.state.ExcludeFoodIds.slice(ExcludeFoodIds_Index + 1) ];

        this.setState({ DishDetail, ExcludeFoodIds, ShowDeleteFoodModal: false }, () => this.props.DeleteDishDetailFood({ ClientId, DishId, FoodId, MealPlanUId }));
    }

    onRefreshDishDetail = () => {
        var { MealPlanUId } = this.props.match.params;

        this.props.GetDishDetail({ uId: MealPlanUId }).then(() => {
            if (this._isMounted) {
                var ExcludeFoodIds = this.props.DishDetail.dataFoods.map(({ FoodId }) => Number(FoodId));
                this.setState({ DishDetail: this.props.DishDetail, ExcludeFoodIds, Loading: false, UpdatingDish: false });
            }
        });
    }

    onSelectUnit = ({ FoodIndex, UnitId }) => {
        var { ClientId } = this.props.ClientDetails;
        var { MealPlanUId } = this.props.match.params;
        var { DishId } = this.state.DishDetail;
        var { FoodId, Quantity } = this.state.DishDetail.dataFoods[FoodIndex];

        this.setState({ ShowFoodUnitsModal: false, UpdatingDish: true }, () => this.props.UpdateDishDetailFood({ ClientId, DishId, FoodId, MealPlanUId, Quantity, UnitId }).then(() => {
            this.onRefreshDishDetail();
        }));
    }

    onToggleAddFoodModal = ShowAddFoodModal => {
        this.setState({ ShowAddFoodModal });
    }

    onToggleDeleteDishModal = ShowDeleteDishModal => {
        this.setState({ ShowDeleteDishModal });
    }

    onToggleDeleteFoodModal = (ShowDeleteFoodModal, FoodId, FoodIndex) => {
        this.setState({ ShowDeleteFoodModal, FoodToDelete: { FoodId, FoodIndex } });
    }

    onToggleFoodUnitsModal = (ShowFoodUnitsModal, FoodIndex, FoodUnits, SelectedUnitId) => {
        this.setState({ ShowFoodUnitsModal, FoodUnitsData: { FoodIndex, FoodUnits, SelectedUnitId } });
    }

    onToggleNutritionModal = ShowNutritionModal => {
        this.setState({ ShowNutritionModal });
    }

    onUpdateDishCompletionStatus = () => {
        var { MealPlanUId: uId } = this.props.match.params;
        var { IsDone } = this.state.DishDetail;

        var DoneStatus = !Number(IsDone);
        DoneStatus = DoneStatus ? 1 : 0;

        this.props.UpdateDishCompletionStatus({ DoneStatus, uId }).then(() => {
            this.setState({ DishDetail: { ...this.state.DishDetail, IsDone: DoneStatus } });
        });
    }

    onUpdateFoodDetail = FoodIndex => {
        var { ClientId } = this.props.ClientDetails;
        var { MealPlanUId } = this.props.match.params;
        var { DishId } = this.state.DishDetail;
        var { FoodId, Quantity, UnitId } = this.state.DishDetail.dataFoods[FoodIndex];

        this.setState({ UpdatingDish: true }, () => this.props.UpdateDishDetailFood({ ClientId, DishId, FoodId, MealPlanUId, Quantity, UnitId }).then(() => {
            this.onRefreshDishDetail();
        }));
    }

    renderAddFoodModal = () => {
        var { ExcludeFoodIds, ShowAddFoodModal } = this.state;

        if (ShowAddFoodModal) {
            return (
                <AddFood
                    ExcludeFoodIds={ExcludeFoodIds}
                    IngredientsShown={[ 1, 2 ]}
                    OnAddFood={Food => this.onAddFood(Food)}
                    OnHide={() => this.onToggleAddFoodModal(false)}
                    Show={ShowAddFoodModal}
                />
            );
        }
    }

    renderDeleteDishModal = () => {
        var { ShowDeleteDishModal } = this.state;

        if (ShowDeleteDishModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteDish}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('MealPlan_are_you_sure_you_want_to_remove_this_dish')}
                    OnHide={() => this.onToggleDeleteDishModal(false)}
                    Show={ShowDeleteDishModal}
                    Size="small"
                    TextAlign="center"
                />
            );
        }
    }

    renderDeleteFoodModal = () => {
        var { ShowDeleteFoodModal } = this.state;

        if (ShowDeleteFoodModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteFood}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('MealPlan_are_you_sure_you_want_to_remove_this_ingredient')}
                    // Loading={this.props.TryingDeleteDishDetailFood}
                    OnHide={() => this.onToggleDeleteFoodModal(false)}
                    Show={ShowDeleteFoodModal}
                    Size="small"
                    TextAlign="center"
                />
            );
        }
    }

    renderFoodUnitsModal = () => {
        var { FoodUnitsData: { FoodIndex, FoodUnits: SelectedFoodUnits, SelectedUnitId }, ShowFoodUnitsModal } = this.state;

        if (ShowFoodUnitsModal) {
            return (
                <FoodUnits
                    FoodUnits={SelectedFoodUnits}
                    OnHide={() => this.onToggleFoodUnitsModal(false)}
                    OnSelectUnit={({ UnitId }) => this.onSelectUnit({ FoodIndex, UnitId })}
                    SelectedUnitId={Number(SelectedUnitId)}
                    Show={ShowFoodUnitsModal}
                />
            );
        }
    }

    renderIngredients = DishDetail => {
        var { t } = this.props;

        return (
            <IngredientsContainer>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('ingredients')} />
                <IngredientListContainer>
                    {
                        DishDetail.dataFoods.map(({ dataUnits, FoodId, FoodName, Quantity, UnitId, UnitName }, FoodIndex) => {
                            return (
                                <IngredientContainer key={FoodId}>
                                    <IngredientNameStyled FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FoodName} TextAlign="left" />
                                    <IngredientOptionsContainer>
                                        <IngredientQuantityStyledInput
                                            FontFamily="semibold"
                                            NoLabel
                                            NoMargin
                                            OnBlur={() => this.onBlurFoodQuantity(FoodIndex)}
                                            OnChange={event => this.onChangeFoodQuantity(event, FoodIndex)}
                                            Type="number"
                                            Value={Quantity}
                                        />
                                        <IngredientUnit FontSize="medium-1" NoMargin OnClick={() => this.onToggleFoodUnitsModal(true, FoodIndex, dataUnits, UnitId)} OnClickTarget="container" Text={UnitName} />
                                        <DeleteIcon onClick={() => this.onDeleteFood({ FoodId, FoodIndex })}>X</DeleteIcon>
                                    </IngredientOptionsContainer>
                                </IngredientContainer>
                            );
                        })
                    }
                    <ButtonGroup
                        Buttons={[
                            { Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleAddFoodModal(true), Title: `${t('MealPlan_Add_ingredient')}`}
                        ]}
                    />
                </IngredientListContainer>
            </IngredientsContainer>
        );
    }

    renderNutritionModal = () => {
        var { ShowNutritionModal } = this.state;

        if (ShowNutritionModal) {
            var { MealPlanUId } = this.props.match.params;
            var { ClientId } = this.props.ClientDetails;
            var { DishDetail: { DishId } } = this.state;

            return (
                <NutritionFacts
                    ClientId={Number(ClientId)}
                    DishId={Number(DishId)}
                    OnHide={() => this.onToggleNutritionModal(false)}
                    Show={ShowNutritionModal}
                    uId={Number(MealPlanUId)}
                />
            );
        }
    }

    renderSection1 = () => {
        // DishImage, CookingTimes, Nutrition Facts
        var { t } = this.props;
        var { Device } = this.props;
        var { DishDetail, IsLoggedFood } = this.state;

        if (IsLoggedFood) return null;

        return (
            <Section1>
                <DishImageContainer>
                    <StyledBackgroundImage
                        ImageSrc={DishDetail.DishUrl}
                    />
                    <Spacer Size="small" />
                    <StyledButtonGroup
                        Buttons={[
                            { Title: <><PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={t('MealPlan_Preparing_time')} />&nbsp;<PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={`- ${DishDetail.PrepareTime} ${t('min_str')}`} /></> },
                            { Title: <><PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={t('MealPlan_Cooking_Time')} />&nbsp;<PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={`- ${DishDetail.ActiveTime} ${t('min_str')}`} /></> }
                        ]}
                        NormalWhiteSpace
                        NotTouching
                        OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                    />
                </DishImageContainer>
                {/* <ColumnMargin />
                <DishImageContainer>
                    <StyledBackgroundImage
                        ImageSrc={DishDetail.DishUrl}
                    />
                    <Spacer Size="small" />
                    <StyledButtonGroup
                        Buttons={[
                            { Title: `${t('MealPlan_Preparing_time')} - ${DishDetail.PrepareTime} ${t('min_str')}`},
                            { Title: `${t('MealPlan_Cooking_Time')} - ${DishDetail.CookingTime} ${t('min_str')}`}
                        ]}
                        NormalWhiteSpace
                        NotTouching
                    />
                </DishImageContainer> */}
            </Section1>
        );
    }

    renderSection2 = () => {
        // Ingredients, Instructions
        var { t } = this.props;
        var { Device } = this.props;
        var { DishDetail, IsLoggedFood } = this.state;

        if (IsLoggedFood) return <ResponsiveColumns>{this.renderIngredients(DishDetail)}</ResponsiveColumns>

        return (
            <ResponsiveColumns>
                {this.renderIngredients(DishDetail)}
                {
                    Device === 'laptop' ?
                    <ColumnMargin />
                :
                    <div><Spacer Size="large" /></div>
                }
                {
                    DishDetail.dataInstructions.length ?
                    <InstructionsContainer>
                        <PageText FontFamily="medium" FontSize="medium-3" Text={t('instructions')} />
                        {
                            DishDetail.dataInstructions.map(({ InstructionName }, InstructionIndex) => {
                                return (
                                    <InstructionContainer key={InstructionIndex}>
                                        <PageText ElementType="span" FontFamily="bold" FontSize="medium-1" NoMargin Text={`${InstructionIndex + 1} -`} />
                                        &nbsp;
                                        <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={`${InstructionName}`} />
                                    </InstructionContainer>
                                );
                            })
                        }
                    </InstructionsContainer>
                :
                    null
                }
            </ResponsiveColumns>
        );
    }

    renderSection3() {
        // Complete / Incomplete, Delete Dish
        var { t } = this.props;
        var { Device } = this.props;
        var { DishDetail, IsLoggedFood } = this.state;

        var Buttons = [{ Color: 'blue-astronaut', FontFamily: 'semibold', OnClick: this.onUpdateDishCompletionStatus, Title: `${t(IsLoggedFood ? Number(DishDetail.IsDone) ? 'ptadmin_log_markincomplete' : 'ptadmin_log_markcomplete' : Number(DishDetail.IsDone) ? 'ptadmin_meal_markincomplete' : 'ptadmin_meal_markcomplete')}` }];
        if (!Number(DishDetail.IsDone)) Buttons.push({ FontFamily: 'semibold', Icon: DeleteX, IconPosition: 'left', OnClick: () => this.onToggleDeleteDishModal(true), Title: `${t(IsLoggedFood ? 'MealPlan_Delete_log' : 'MealPlan_Delete_dish')}` });

        return (
            <ButtonGroup
                Buttons={Buttons}
                ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                NotTouching
                OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
            />
        );
    }

    render() {
        if (this.state.Loading || (this.props.TryingGetDishDetail && !this.state.UpdatingDish)) return <Loading />;

        var { t } = this.props;
        var { Device, ClientDetails, DishDetail, TryingDeleteDishDetailFood, TryingUpdateDishCompletionStatus, TryingUpdateDishDetailFood } = this.props;
        var { IsLoggedFood, UpdatingDish } = this.state;

        return (
            <>
                {TryingDeleteDishDetailFood || TryingUpdateDishCompletionStatus || TryingUpdateDishDetailFood || UpdatingDish ? <Loading /> : null}

                <Page
                    renderBreadcrumbs={[
                        { Name: `${ClientDetails.FirstName && ClientDetails.LastName ? `${ClientDetails.FirstName} ${ClientDetails.LastName}` : ClientDetails.Email}`, LinkURL: `client/${ClientDetails.ClientId}`, Props: { MealDate: DishDetail.MealDate, SelectedTab: 'Meals' }, Search: '?tab=meals' },
                        { Name: IsLoggedFood ? t('logged_food_title') : DishDetail.DishName }
                    ]}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={IsLoggedFood ? t('logged_food_title') : DishDetail.DishName} />

                    <Spacer Size="medium" />

                    {/* DishImage, CookingTimes, Nutrition Facts */}
                    {this.renderSection1()}

                    <Spacer Size="medium" />

                    <ButtonGroup
                        Buttons={[
                            { OnClick: () => this.onToggleNutritionModal(true), Title: `${t('MealPlan_Nutrition_in_this_dish')}`}
                        ]}
                    />

                    <Spacer Size={Device === 'laptop' ? 'extra-extra-large' : 'large'} />

                    {/* Dish Ingredients, Dish Instructions */}
                    {this.renderSection2()}

                    <Spacer Size={Device === 'laptop' ? 'extra-extra-large' : 'large'} />

                    {/* Dish Option Buttons */}
                    {this.renderSection3()}
                </Page>

                {this.renderAddFoodModal()}
                {this.renderDeleteDishModal()}
                {this.renderDeleteFoodModal()}
                {this.renderFoodUnitsModal()}
                {this.renderNutritionModal()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ClientDetails: state.Client.ClientDetails,
        DishDetail: state.Meal.DishDetail,

        TryingDeleteDishDetailFood: state.Meal.TryingDeleteDishDetailFood,
        TryingDeleteDishDetailFoodError: state.Meal.TryingDeleteDishDetailFoodError,
        TryingGetDishDetail: state.Meal.TryingGetDishDetail,
        TryingGetDishDetailError: state.Meal.TryingGetDishDetailError,
        TryingUpdateDishCompletionStatus: state.Meal.TryingUpdateDishCompletionStatus,
        TryingUpdateDishCompletionStatusError: state.Meal.TryingUpdateDishCompletionStatusError,
        TryingUpdateDishDetailFood: state.Meal.TryingUpdateDishDetailFood,
        TryingUpdateDishDetailFoodError: state.Meal.TryingUpdateDishDetailFoodError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearClientDetails, ClearDishDetail, DeleteMealDish, DeleteDishDetailFood, GetDishDetail, UpdateDishCompletionStatus, UpdateDishDetailFood, UpdateSelectedTab } )(DishDetail));
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearPrivateExerciseSettings, GetPreSignedS3Url, InsertEditPrivateExercise, SaveDataInProps, UploadToPresignedUrl, ViewCreatePrivateExerciseSettings } from '../../Services/Actions';
import history from '../../history';

import { BETA_SITE_URL } from '../../Config';

import { CheckmarkContainer, ExerciseImageInputContainer, ExerciseMediaContainer, ExerciseMediaParentContainer, JointEquipmentContainer, JointEquipmentItem, MusclesContainer, MuscleListColumn, MusclesListContainer, MusclesListContainerSection, MuscleListRow, PseudoCheckbox, StyledBodyMuscles, StyledButtonGroupExerciseTypes, StyledCheckmark, StyledExerciseVideo, StyledMediaButtonGroup, StyledMultiText } from './styles';

// import FlexWrap from '../../Components/Lists/FlexWrap';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import TranslationsComponent from '../../Components/Translations';
import UploadProgressComponent from '../../Components/UploadProgress';

import Camera from '../../Assets/Icons/Camera.png';
import Plus from '../../Assets/Icons/Plus.png';

import { CreateUploadToPresignedUrlPromise, getFileExtension, validateFile } from '../../Functions';

const MUSCLE_TYPES = [ 'Primary', 'Secondary', 'Tertiary' ];

class Exercise extends React.Component {
    _isMounted = false;

    state = {
        CopiedExerciseId: null,
        CopyImage: false,
        CopyVideo: false,
        Equipment: [],
        ExerciseImage: null,
        ExerciseType: null,
        ExerciseVideo: null,
        ImageTooLarge: false,
        IsMuted: false,
        Joints: [],
        MaxImageSize: 10000000,
        MaxVideoSize: 25000000,
        Movement: [],
        Muscles: [],
        Muscles_FirstHalf: [],
        Muscles_SecondHalf: [],
        MuscleUsage: { PrimaryMuscles: [], SecondaryMuscles: [], TertiaryMuscles: [] },
        NeedsEncoding: false,
        Loading: true,
        Plane: [],
        SelectedImage: null,
        SelectedVideo: null,
        StartPosition: [],
        Tags: [],
        TrainingFocus: [],
        TrainingForm: [],
        Translations: { DefaultLanguageId: null, Languages: [], Translations: {} },
        VideoProcessing: false,
        VideoTooLarge: false,
        WrongImageType: false,
        WrongVideoType: false
    };

    componentDidMount() {
        var ExerciseToExerciseDetails = this.props.location.state;
        var { ExerciseId } = this.props.match.params;

        var RedirectLink = `${BETA_SITE_URL}/workout-library/exercise`;

        if (ExerciseToExerciseDetails && !!ExerciseToExerciseDetails.hasOwnProperty('ExerciseDetails')) RedirectLink += `/${ExerciseToExerciseDetails.CopiedExerciseId}?createFromExistingExercise=true`;
        else if (!!+ExerciseId) RedirectLink += `/${ExerciseId}`;

        window.location = RedirectLink;

        // if (Object.entries(CopyDishDetail).length !== 0) RedirectLink += `/${CopyDishDetail.DishId}?createFromExistingDish=true`;
        // else if (!!+ExerciseId) RedirectLink += `/${ExerciseId}`;

        // window.location = RedirectLink;

        // this._isMounted = true;

        // var { ExerciseId } = this.props.match.params;
        // ExerciseId = +ExerciseId || null;

        // this.props.ViewCreatePrivateExerciseSettings({ ExerciseId }).then(() => {
        //     if (this._isMounted && !this.props.TryingGetCreatePrivateExerciseSettingsError) {
        //         var { Exercise, Translations } = this.props.CustomExerciseSettings;
        //         // eslint-disable-next-line
        //         if (!!Exercise && Object.entries(Exercise).length > 0) var { Equipment, ExerciseImage, ExerciseType, ExerciseVideo, Joints, Movement, Muscles, Plane, PrimaryMuscles, SecondaryMuscles, StartPosition, Tags, TertiaryMuscles, TrainingFocus, TrainingForm } = Exercise;

        //         var CopyImage = false;
        //         var CopyVideo = false;

        //         var Muscles_FirstHalf = [];
        //         var Muscles_SecondHalf = [];

        //         if (!Number.isInteger(ExerciseId)) {
        //             var ExerciseToExerciseDetails = this.props.location.state;

        //             // eslint-disable-next-line
        //             var { Muscles } = this.props.CustomExerciseSettings;

        //             if (ExerciseToExerciseDetails && !!ExerciseToExerciseDetails.hasOwnProperty('ExerciseDetails')) {
        //                 // eslint-disable-next-line
        //                 var { CopiedExerciseId } = ExerciseToExerciseDetails;
        //                 // eslint-disable-next-line
        //                 var { Equipment, ExerciseImage, ExerciseType, ExerciseVideo, Movement, Plane, StartPosition, TrainingFocus, TrainingForm, Translations, UsedJoints, UsedMuscles } = ExerciseToExerciseDetails.ExerciseDetails;
        //                 Joints = UsedJoints;
        //                 PrimaryMuscles = UsedMuscles.PrimaryMuscleIds;
        //                 SecondaryMuscles = UsedMuscles.SecondaryMuscleIds;
        //                 TertiaryMuscles = UsedMuscles.TertiaryMuscleIds;

        //                 if (ExerciseImage) CopyImage = true;
        //                 if (ExerciseVideo) CopyVideo = true;
        //             }

        //             Muscles_FirstHalf = [ ...Muscles.slice(0, 11) ];
        //             Muscles_SecondHalf = [ ...Muscles.slice(11, Muscles.length) ];

        //             // Joints = Joints ? Joints.split(',').map(Number) : [];
        //             // Muscles = Muscles ? Muscles.split(',').map(Number) : [];

        //             this.setState({
        //                 CopiedExerciseId,
        //                 CopyImage, CopyVideo,
        //                 Equipment: Equipment || [],
        //                 ExerciseId: ExerciseId || null,
        //                 ExerciseImage: ExerciseImage || null,
        //                 ExerciseType: ExerciseType || null,
        //                 ExerciseVideo: ExerciseVideo || null,
        //                 Joints: Joints || [],
        //                 Movement: Movement || [],
        //                 Muscles: Muscles || [],
        //                 Muscles_FirstHalf, Muscles_SecondHalf,
        //                 MuscleUsage: {
        //                     PrimaryMuscles: PrimaryMuscles || [],
        //                     SecondaryMuscles: SecondaryMuscles || [],
        //                     TertiaryMuscles: TertiaryMuscles || [],
        //                 },
        //                 Plane: Plane || [],
        //                 StartPosition: StartPosition || [],
        //                 Tags: Tags || [],
        //                 TrainingFocus: TrainingFocus || [],
        //                 TrainingForm: TrainingForm || [],
        //                 Translations,
        //                 Loading: false
        //             });
        //         } else {
        //             if (!!Exercise && Object.entries(Exercise).length > 0) {
        //                 Equipment = Equipment ? Equipment.split(',').map(Number) : [];
        //                 Joints = Joints ? Joints.split(',').map(Number) : [];
        //                 Movement = Movement ? Movement.split(',').map(Number) : [];
        //                 Muscles = Muscles ? Muscles.split(',').map(Number) : [];
        //                 Plane = Plane ? Plane.split(',').map(Number) : [];
        //                 PrimaryMuscles = PrimaryMuscles ? PrimaryMuscles.split(',').map(Number) : [];
        //                 SecondaryMuscles = SecondaryMuscles ? SecondaryMuscles.split(',').map(Number) : [];
        //                 StartPosition = StartPosition ? StartPosition.split(',').map(Number) : [];
        //                 Tags = Tags ? Tags.split(',').map(Number) : [];
        //                 TertiaryMuscles = TertiaryMuscles ? TertiaryMuscles.split(',').map(Number) : [];
        //                 TrainingFocus = TrainingFocus ? TrainingFocus.split(',').map(Number) : [];
        //                 TrainingForm = TrainingForm ? TrainingForm.split(',').map(Number) : [];

        //                 Muscles_FirstHalf = [ ...this.props.CustomExerciseSettings.Muscles.slice(0, 8) ];
        //                 Muscles_SecondHalf = [ ...this.props.CustomExerciseSettings.Muscles.slice(8, this.props.CustomExerciseSettings.Muscles.length) ];
        //             }

        //             this.setState({
        //                 CopyImage, CopyVideo,
        //                 Equipment: Equipment || [],
        //                 ExerciseId: ExerciseId || null,
        //                 ExerciseImage: ExerciseImage || null,
        //                 ExerciseType: ExerciseType || null,
        //                 ExerciseVideo: ExerciseVideo || null,
        //                 Joints: Joints || [],
        //                 Movement: Movement || [],
        //                 Muscles: Muscles || [],
        //                 Muscles_FirstHalf, Muscles_SecondHalf,
        //                 MuscleUsage: {
        //                     PrimaryMuscles: PrimaryMuscles || [],
        //                     SecondaryMuscles: SecondaryMuscles || [],
        //                     TertiaryMuscles: TertiaryMuscles || [],
        //                 },
        //                 Plane: Plane || [],
        //                 StartPosition: StartPosition || [],
        //                 Tags: Tags || [],
        //                 TrainingFocus: TrainingFocus || [],
        //                 TrainingForm: TrainingForm || [],
        //                 Loading: false,
        //                 VideoProcessing: Exercise.VideoProcessing,
        //                 Translations
        //             });
        //         }
        //     } else this.setState({ Loading: false });
        // });
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearPrivateExerciseSettings();
    }

    onAddLanguage = () => {
        var NewTranslations = { ...this.state.Translations };

        var FirstNonUsedLanguage = NewTranslations.Languages.filter(({ LanguageId }) => !NewTranslations.Translations.hasOwnProperty(`${LanguageId}`))[0].LanguageId;

        NewTranslations.Translations = { ...NewTranslations.Translations, [`${FirstNonUsedLanguage}`]: { ExerciseInstructions: '', ExerciseName: '', LanguageId: +FirstNonUsedLanguage } };

        this.setState({ Translations: NewTranslations });
    }

    onChangeDefault = LanguageId => {
        var NewTranslations = { ...this.state.Translations, DefaultLanguageId: LanguageId };

        this.setState({ Translations: NewTranslations });
    }

    onChangeLanguage = ({ NewLanguage, TranslationKey }) => {
        if (+NewLanguage.LanguageId !== +TranslationKey) {
            var NewTranslations = { ...this.state.Translations };
            NewTranslations.Translations = { ...NewTranslations.Translations, [`${NewLanguage.LanguageId}`]: { ...NewTranslations.Translations[`${TranslationKey}`] } };
            delete NewTranslations.Translations[`${TranslationKey}`];

            // If DefaultLanguageId Is The Language That Changes, Then Set DefaultLanguageId to NewLanguage
                if (+NewTranslations.DefaultLanguageId === +TranslationKey) NewTranslations = { ...NewTranslations, DefaultLanguageId: NewLanguage.LanguageId };

            this.setState({ Translations: NewTranslations });
        }
    }

    onChangeTranslation = ({ event, InputName, LanguageId }) => {
        var NewTranslations = { ...this.state.Translations };
        NewTranslations.Translations = { ...NewTranslations.Translations, [`${LanguageId}`]: { ...NewTranslations.Translations[`${LanguageId}`], [InputName]: event.target.value } };

        this.setState({ Translations: NewTranslations });
    }

    onDeleteLanguage = LanguageId => {
        var NewTranslations = { ...this.state.Translations };
        NewTranslations.Translations = { ...NewTranslations.Translations };
        delete NewTranslations.Translations[`${LanguageId}`];

        this.setState({ Translations: NewTranslations });
    }

    onInsertEditExercise = () => {
        var { ExerciseId } = this.props.match.params;
        var { CopiedExerciseId, CopyImage, CopyVideo, Equipment, ExerciseType, IsMuted, Joints, Movement, MuscleUsage, Plane, SelectedImage, SelectedVideo, StartPosition, Tags, TrainingFocus, TrainingForm, Translations } = this.state;

        Equipment = Equipment.join(',');
        Joints = Joints.join(',');
        Movement = Movement.join(',');
        Plane = Plane.join(',');
        StartPosition = StartPosition.join(',');
        Tags = Tags.join(',');
        TrainingFocus = TrainingFocus.join(',');
        TrainingForm = TrainingForm.join(',');

        var PrimaryMuscles = MuscleUsage.PrimaryMuscles.join(',');
        var SecondaryMuscles = MuscleUsage.SecondaryMuscles.join(',');
        var TertiaryMuscles = MuscleUsage.TertiaryMuscles.join(',');

        var NewTranslations = { DefaultLanguageId: Translations.DefaultLanguageId, Translations: [] };

        NewTranslations.Translations = Object.keys(Translations.Translations).map(key => {
            var { ExerciseInstructions, ExerciseName } = Translations.Translations[key];

            return { LanguageId: key, Name: ExerciseName, Instructions: ExerciseInstructions };
        });

        this.props.InsertEditPrivateExercise({ CopiedExerciseId, CopyImage: +!!CopyImage, CopyVideo: +!!CopyVideo, Equipment, ExerciseId, ExerciseImage: SelectedImage ? { ContentType: SelectedImage.type, FileName: SelectedImage.name } : null, ExerciseType, ExerciseVideo: SelectedVideo ? { ContentType: SelectedVideo.type, FileName: SelectedVideo.name, IsMuted: +IsMuted } : null, Joints, Movement, Plane, PrimaryMuscles, SecondaryMuscles, StartPosition, Tags, TertiaryMuscles, TrainingFocus, TrainingForm, Translations: NewTranslations }).then(({ PresignedFormData_Image, PresignedFormData_Video }) => {
            if (this._isMounted && !this.props.TryingInsertEditPrivateExerciseError) {
                // If Uploaded Video or Image, Then Use PreSignedUrl to Upload To AWS
                if ((SelectedVideo && PresignedFormData_Video) || (SelectedImage && PresignedFormData_Image)) {
                    var Promises = [];
                    var UploadFunction = this.props.UploadToPresignedUrl;

                    if (PresignedFormData_Image) Promises.push(CreateUploadToPresignedUrlPromise({ ContentType: 'CustomExercise', File: SelectedImage, PresignedFormData: PresignedFormData_Image, UploadFunction }));
                    if (PresignedFormData_Video) Promises.push(CreateUploadToPresignedUrlPromise({ ContentType: 'CustomExercise', File: SelectedVideo, PresignedFormData: PresignedFormData_Video, UploadFunction }));

                    Promise.all(Promises).then(() => {
                        if (this.props.location.state && this.props.location.state.ReturnData && Object.entries(this.props.location.state.ReturnData).length > 0) history.push({ ...this.props.location.state.ReturnData });
                        else history.push({ pathname: '/library-workouts', search: '?tab=exercises' });
                    });

                } else {
                    if (this.props.location.state && this.props.location.state.ReturnData && Object.entries(this.props.location.state.ReturnData).length > 0) history.push({ ...this.props.location.state.ReturnData });
                    else history.push({ pathname: '/library-workouts', search: '?tab=exercises' });
                }
            }
        });
    }

    onSelectEquipment = ({ EquipmentId, EquipmentIndex }) => {
        if (EquipmentIndex === -1) this.setState({ Equipment: [ ...this.state.Equipment, +EquipmentId ] });
        else this.setState({ Equipment: [ ...this.state.Equipment.slice(0, EquipmentIndex), ...this.state.Equipment.slice(EquipmentIndex + 1) ] });
    }

    onSelectExerciseImage = event => {
        if (event.target.files.length) {
            var SelectedImage = event.target.files[0];
            var FileExtension = getFileExtension(SelectedImage.name);

            if (validateFile({ FileExtension, FileType: 'Images' })) {
                if (SelectedImage.size <= this.state.MaxImageSize) {
                    this.setState({ CopyImage: false, ExerciseImage: URL.createObjectURL(SelectedImage), ImageTooLarge: false, SelectedImage, WrongImageType: false});
                } else {
                    document.getElementById('ExerciseImage').value = null;
                    this.setState({ CopyImage: false, ImageTooLarge: true, WrongImageType: false });
                }
            } else this.setState({ ImageTooLarge: false, WrongImageType: true });
        }
    }

    onSelectExerciseVideo = event => {
        if (event.target.files.length) {
            var SelectedVideo = event.target.files[0];
            var FileExtension = getFileExtension(SelectedVideo.name);

            if (validateFile({ FileExtension, FileType: 'Videos' })) {
                // if (SelectedVideo.size <= this.state.MaxVideoSize) {
                    var ExerciseVideo = URL.createObjectURL(SelectedVideo);

                    this.setState({ CopyVideo: false, NeedsEncoding: FileExtension !== 'mp4' ? true : false, SelectedVideo, ExerciseVideo, VideoTooLarge: false, WrongVideoType: false }, () => {
                        var ExerciseVideoElement = document.getElementById('video');
                        ExerciseVideoElement.src = ExerciseVideo;
                        ExerciseVideoElement.load();
                    });
                // }
                // else {
                //     document.getElementById('ExerciseVideo').value = null;
                //     this.setState({ CopyVideo: false, VideoTooLarge: true, WrongVideoType: false, NeedsEncoding: false });
                // }
            } else {
                document.getElementById('ExerciseVideo').value = null;
                this.setState({ CopyVideo: false, WrongVideoType: true, VideoTooLarge: false, NeedsEncoding: false });
            }
        } else this.setState({ SelectedVideo: null, ExerciseVideo: null, VideoTooLarge: false, WrongVideoType: false, NeedsEncoding: false });
    }

    onSelectExerciseType = ExerciseType => {
        this.setState({ ExerciseType });
    }

    onSelectJoint = ({ JointId, JointIndex }) => {
        if (JointIndex === -1) this.setState({ Joints: [ ...this.state.Joints, +JointId ] });
        else this.setState({ Joints: [ ...this.state.Joints.slice(0, JointIndex), ...this.state.Joints.slice(JointIndex + 1) ] });
    }

    onSelectMovement = ({ MovementId, MovementIndex }) => {
        if (MovementIndex === -1) this.setState({ Movement: [ ...this.state.Movement, +MovementId ] });
        else this.setState({ Movement: [ ...this.state.Movement.slice(0, MovementIndex), ...this.state.Movement.slice(MovementIndex + 1) ] });
    }

    onSelectMuscle_BodyMuscles = ({ MuscleGroupId, MuscleType = null }) => {
        var PrimaryMuscles = [ ...this.state.MuscleUsage.PrimaryMuscles ]; var PrimaryMuscleIndex = PrimaryMuscles.indexOf(+MuscleGroupId);
        var SecondaryMuscles = [ ...this.state.MuscleUsage.SecondaryMuscles ]; var SecondaryMuscleIndex = SecondaryMuscles.indexOf(+MuscleGroupId);
        var TertiaryMuscles = [ ...this.state.MuscleUsage.TertiaryMuscles ]; var TertiaryMuscleIndex = TertiaryMuscles.indexOf(+MuscleGroupId);

        // Selected from checkmark list, so add / remove directly
            if (MuscleType !== null) {
                if (MuscleType === 'primary') {
                    // Add To Primary Muscles
                        if (PrimaryMuscleIndex === -1) PrimaryMuscles = [ ...PrimaryMuscles, +MuscleGroupId ];
                    // Remove From Primary Muscles
                        else PrimaryMuscles = [ ...PrimaryMuscles.slice(0, PrimaryMuscleIndex), ...PrimaryMuscles.slice(PrimaryMuscleIndex + 1) ];

                    // Remove From Secondary and Tertiary If Required
                        if (SecondaryMuscleIndex !== -1) SecondaryMuscles = [ ...SecondaryMuscles.slice(0, SecondaryMuscleIndex), ...SecondaryMuscles.slice(SecondaryMuscleIndex + 1) ];
                        else if (TertiaryMuscleIndex !== -1) TertiaryMuscles = [ ...TertiaryMuscles.slice(0, TertiaryMuscleIndex), ...TertiaryMuscles.slice(TertiaryMuscleIndex + 1) ];
                }
                else if (MuscleType === 'secondary') {
                    // Add To Secondary Muscles
                        if (SecondaryMuscleIndex === -1) SecondaryMuscles = [ ...SecondaryMuscles, +MuscleGroupId ];
                    // Remove From Secondary Muscles
                        else SecondaryMuscles = [ ...SecondaryMuscles.slice(0, SecondaryMuscleIndex), ...SecondaryMuscles.slice(SecondaryMuscleIndex + 1) ];

                    // Remove From Primary and Tertiary If Required
                        if (PrimaryMuscleIndex !== -1) PrimaryMuscles = [ ...PrimaryMuscles.slice(0, PrimaryMuscleIndex), ...PrimaryMuscles.slice(PrimaryMuscleIndex + 1) ];
                        else if (TertiaryMuscleIndex !== -1) TertiaryMuscles = [ ...TertiaryMuscles.slice(0, TertiaryMuscleIndex), ...TertiaryMuscles.slice(TertiaryMuscleIndex + 1) ];
                }
                else if (MuscleType === 'tertiary') {
                    // Add To Tertiary Muscles
                        if (TertiaryMuscleIndex === -1) TertiaryMuscles = [ ...TertiaryMuscles, +MuscleGroupId ];
                    // Remove From Tertiary Muscles
                        else TertiaryMuscles = [ ...TertiaryMuscles.slice(0, TertiaryMuscleIndex), ...TertiaryMuscles.slice(TertiaryMuscleIndex + 1) ];

                    // Remove From Primary and Secondary If Required
                        if (PrimaryMuscleIndex !== -1) PrimaryMuscles = [ ...PrimaryMuscles.slice(0, PrimaryMuscleIndex), ...PrimaryMuscles.slice(PrimaryMuscleIndex + 1) ];
                        else if (SecondaryMuscleIndex !== -1) SecondaryMuscles = [ ...SecondaryMuscles.slice(0, SecondaryMuscleIndex), ...SecondaryMuscles.slice(SecondaryMuscleIndex + 1) ];
                }
            }
        // Selected from body muscles map, so toggle through
            else if (MuscleGroupId !== 0) {
                // If Primary Muscle, Then Change To Secondary
                    if (PrimaryMuscleIndex !== -1) {
                        PrimaryMuscles = [ ...PrimaryMuscles.slice(0, PrimaryMuscleIndex), ...PrimaryMuscles.slice(PrimaryMuscleIndex + 1) ];
                        SecondaryMuscles = [ ...SecondaryMuscles, +MuscleGroupId ];
                    }
                    else {
                        // If Secondary Muscle, Then Change To Tertiary
                            if (SecondaryMuscleIndex !== -1) {
                                SecondaryMuscles = [ ...SecondaryMuscles.slice(0, SecondaryMuscleIndex), ...SecondaryMuscles.slice(SecondaryMuscleIndex + 1) ];
                                TertiaryMuscles = [ ...TertiaryMuscles, +MuscleGroupId ];
                            }
                            else {
                                // If Tertiary Muscle, Then Change To Nothing
                                    if (TertiaryMuscleIndex !== -1) {
                                        TertiaryMuscles = [ ...TertiaryMuscles.slice(0, TertiaryMuscleIndex), ...TertiaryMuscles.slice(TertiaryMuscleIndex + 1) ];
                                    }
                                    else {
                                        // Change Muscle To Primary If Nothing
                                            PrimaryMuscles = [ ...PrimaryMuscles, +MuscleGroupId ];
                                    }
                            }
                    }
            }

        var NewMuscleUsage = { ...this.state.MuscleUsage, PrimaryMuscles, SecondaryMuscles, TertiaryMuscles };

        this.setState({ MuscleUsage: NewMuscleUsage });
    }

    onSelectPlane = ({ PlaneId, PlaneIndex }) => {
        if (PlaneIndex === -1) this.setState({ Plane: [ ...this.state.Plane, +PlaneId ] });
        else this.setState({ Plane: [ ...this.state.Plane.slice(0, PlaneIndex), ...this.state.Plane.slice(PlaneIndex + 1) ] });
    }

    onSelectStartPosition = ({ StartPositionId, StartPositionIndex }) => {
        if (StartPositionIndex === -1) this.setState({ StartPosition: [ ...this.state.StartPosition, +StartPositionId ] });
        else this.setState({ StartPosition: [ ...this.state.StartPosition.slice(0, StartPositionIndex), ...this.state.StartPosition.slice(StartPositionIndex + 1) ] });
    }

    onSelectTag = ({ TagId, TagIndex }) => {
        if (TagIndex === -1) this.setState({ Tags: [ ...this.state.Tags, +TagId ] });
        else this.setState({ Tags: [ ...this.state.Tags.slice(0, TagIndex), ...this.state.Tags.slice(TagIndex + 1) ] });
    }

    onSelectTrainingFocus = ({ TrainingFocusId, TrainingFocusIndex }) => {
        if (TrainingFocusIndex === -1) this.setState({ TrainingFocus: [ ...this.state.TrainingFocus, +TrainingFocusId ] });
        else this.setState({ TrainingFocus: [ ...this.state.TrainingFocus.slice(0, TrainingFocusIndex), ...this.state.TrainingFocus.slice(TrainingFocusIndex + 1) ] });
    }

    onSelectTrainingForm = ({ TrainingFormId, TrainingFormIndex }) => {
        if (TrainingFormIndex === -1) this.setState({ TrainingForm: [ ...this.state.TrainingForm, +TrainingFormId ] });
        else this.setState({ TrainingForm: [ ...this.state.TrainingForm.slice(0, TrainingFormIndex), ...this.state.TrainingForm.slice(TrainingFormIndex + 1) ] });
    }

    onToggleIsMuted = () => {
        this.setState({ IsMuted: !this.state.IsMuted });
    }

    renderEquipment = () => {
        var { t } = this.props;
        var { CustomExerciseSettings: { Equipment } } = this.props;
        var { Equipment: StateEquipment } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_exercise_select_blank_used', { Item: t('Equipment').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer">
                    {
                        Equipment.map(({ label: EquipmentName, value: EquipmentId }) => {
                            var SelectedIndex = StateEquipment.indexOf(+EquipmentId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={EquipmentId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectEquipment({ EquipmentId, EquipmentIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectEquipment({ EquipmentId, EquipmentIndex: SelectedIndex })} Text={EquipmentName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderExerciseMedia = () => {
        var { t } = this.props;
        var { ExerciseImage, ExerciseVideo, ImageTooLarge, IsMuted, SelectedVideo, VideoProcessing, VideoTooLarge, WrongImageType, WrongVideoType } = this.state;

        var ExerciseImageButtons = [{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg", id: "ExerciseImage", type: "file", onChange: this.onSelectExerciseImage }, Title: t('Upload') }];
        var ExerciseVideoButtons = [{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', IsInput: true, InputProps: { accept: "video/*", id: "ExerciseVideo", type: "file", onChange: this.onSelectExerciseVideo }, Title: t('Upload') }];

        return (
            <ExerciseMediaParentContainer className="ExerciseMediaParentContainer">
                <ExerciseMediaContainer className="ExerciseMediaContainer">
                    <PageText FontColor="blue-astronaut" FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('Profile_upload_a_picture_screenshot_button_title')} TextAlign="left" />

                    {
                        !!ExerciseImage &&
                        <>
                            <Spacer Size="extra-small" />

                            <ExerciseImageInputContainer BackgroundImage={ExerciseImage} />
                        </>
                    }

                    <Spacer Size="small" />

                    <StyledMediaButtonGroup
                        Buttons={ExerciseImageButtons}
                        ButtonWidth="fit-content"
                        NotTouching
                    />

                    <Spacer Size="extra-extra-small" />

                    <StyledMultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('max_upload_size')}:` },
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: '10MB' },
                        ]}
                    />

                    {
                        (ImageTooLarge || WrongImageType) &&
                        <>
                            <Spacer Size="small" />

                            <PageText
                                FontColor="red-bittersweet"
                                FontFamily="semibold-italic"
                                FontSize="medium-1"
                                Text={t(ImageTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.jpg, .png, .jpeg', MaxSize: '10MB', Type: 'Image' })}
                                TextAlign="center"
                            />
                        </>
                    }
                </ExerciseMediaContainer>

                <ExerciseMediaContainer className="ExerciseMediaContainer">
                    <PageText FontColor="blue-astronaut" FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('Profile_upload_a_video_button_title')} TextAlign="left" />

                    {
                        !!VideoProcessing ?
                        <>
                            <Spacer Size="small" />

                            <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={t('video_processing')} TextAlign="center" />
                        </>
                    :
                        <>
                            {
                                !!ExerciseVideo &&
                                <>
                                    <StyledExerciseVideo id="video" { ...ExerciseImage && { poster: ExerciseImage } } controls><source src={ExerciseVideo} type="video/mp4" />Your browser does not support the video tag.</StyledExerciseVideo>

                                    {
                                        SelectedVideo &&
                                        <>
                                            <Spacer Size="small" />

                                            <CheckmarkContainer className="CheckmarkContainer">
                                                <StyledCheckmark
                                                    ItemId={1}
                                                    OnSelectItem={this.onToggleIsMuted}
                                                    SelectedIndex={!!IsMuted ? 0 : -1}
                                                    Title={t('mute_video')}
                                                />

                                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" OnClick={this.onToggleIsMuted} NoMargin Text={t('mute_video')} TextAlign="center" />
                                            </CheckmarkContainer>
                                        </>
                                    }
                                </>
                            }

                            <Spacer Size="small" />

                            <StyledMediaButtonGroup
                                Buttons={ExerciseVideoButtons}
                                ButtonWidth="fit-content"
                                NotTouching
                            />

                            <Spacer Size="extra-extra-small" />

                            <StyledMultiText
                                TextAlign="left"
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('max_upload_size')}:` },
                                    { FontFamily: 'medium', FontSize: 'medium-1', Text: '25MB' },
                                ]}
                            />
                        </>
                    }

                    {
                        (VideoTooLarge || WrongVideoType) &&
                        <>
                            <Spacer Size="small" />

                            <PageText
                                FontColor="red-bittersweet"
                                FontFamily="semibold-italic"
                                FontSize="medium-1"
                                Text={t(VideoTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.mp4, .mov', MaxSize: '25MB', Type: 'Video' })}
                                TextAlign="center"
                            />
                        </>
                    }
                </ExerciseMediaContainer>
            </ExerciseMediaParentContainer>
        );
    }

    renderExerciseTypes = () => {
        var { t } = this.props;
        var { CustomExerciseSettings: { ExerciseTypes } } = this.props;
        var { ExerciseId } = this.state;

        var ExerciseTypeButtons = [];
        var ExerciseTypeButton = { BackgroundColor: 'white-concrete', BackgroundColorHover: 'blue-astronaut', Border: 'none', ColorHover: 'white', FontFamily: 'semibold'};

        ExerciseTypeButtons = ExerciseTypes.map(({ ExerciseType, ExerciseTypeName }) => ({ ...ExerciseTypeButton, Disabled: !!ExerciseId ? ExerciseType === this.state.ExerciseType ? false : true : false, OnClick: () => this.onSelectExerciseType(ExerciseType), Selected: ExerciseType === this.state.ExerciseType, Title: ExerciseTypeName }));

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[
                        { FontColor: 'blue-astronaut', FontFamily: 'bold', FontSize: 'medium-3', Text: t('custom_exercise_select_exercisetype') },
                        { FontColor: 'red-bittersweet', FontFamily: 'bold', FontSize: 'medium-3', Text: '*' },
                    ]}
                />
                
                <Spacer Size="small" />

                <StyledButtonGroupExerciseTypes
                    Buttons={ExerciseTypeButtons}
                    ButtonWidth="fit-content"
                    NotTouching
                    OwnRows={false}
                />
            </>
        );
    }

    renderJoints = () => {
        var { t } = this.props;
        var { CustomExerciseSettings: { Joints } } = this.props;
        var { Joints: StateJoints } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_exercise_select_blank_used', { Item: t('Joints').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="Joints">
                    {
                        Joints.map(({ label: JointName, value: JointId }) => {
                            var SelectedIndex = StateJoints.indexOf(+JointId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={JointId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectJoint({ JointId, JointIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectJoint({ JointId, JointIndex: SelectedIndex })} Text={JointName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderMovement = () => {
        var { t } = this.props;
        var { CustomExerciseSettings: { Movement } } = this.props;
        var { Movement: StateMovement } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_exercise_select_blank_used', { Item: t('_movement').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="Movement">
                    {
                        Movement.map(({ label: MovementName, value: MovementId }) => {
                            var SelectedIndex = StateMovement.indexOf(+MovementId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={MovementId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectMovement({ MovementId, MovementIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectMovement({ MovementId, MovementIndex: SelectedIndex })} Text={MovementName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderMuscles = () => {
        var { t } = this.props;
        var { MuscleUsage } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[
                        { FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_exercise_select_blank_used', { Item: t('Muscles').toLowerCase()})}` },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' },
                    ]}
                />

                <Spacer Size="extra-small" />

                <MusclesContainer className="MusclesContainer">
                    <StyledBodyMuscles
                        OnSelectMuscle={MuscleGroupId => this.onSelectMuscle_BodyMuscles({ MuscleGroupId })}
                        PrimaryMuscleIds={MuscleUsage.PrimaryMuscles}
                        SecondaryMuscleIds={MuscleUsage.SecondaryMuscles}
                        TertiaryMuscleIds={MuscleUsage.TertiaryMuscles}
                    />

                    {this.renderMusclesList()}
                </MusclesContainer>
            </>
        );
    }

    renderMusclesList = () => {
        var { Muscles_FirstHalf, Muscles_SecondHalf } = this.state;

        return (
            <MusclesListContainer className="MusclesListContainer">
                {this.renderMusclesList_Section(Muscles_FirstHalf)}
                {this.renderMusclesList_Section(Muscles_SecondHalf)}
            </MusclesListContainer>
        );
    }

    renderMusclesList_Section = MusclesList => {
        var { t } = this.props;
        var { MuscleUsage: { PrimaryMuscles, SecondaryMuscles, TertiaryMuscles } } = this.state;

        return (
            <MusclesListContainerSection className="MusclesListContainerSection">
                <MuscleListRow className="MuscleListRow" Names>
                    {
                        MUSCLE_TYPES.map((MuscleType, MuscleTypeIndex) => {
                            return (
                                <MuscleListColumn className="MuscleListColumn" key={MuscleTypeIndex}>
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t(`WorkoutPlan_${MuscleType}`)} TextAlign="center" />
                                </MuscleListColumn>
                            );
                        })
                    }
                    <MuscleListColumn />
                </MuscleListRow>
                {
                    MusclesList.map(({ label: MuscleName, value: MuscleGroupId }) => {
                        var UsedMuscleType = PrimaryMuscles.indexOf(+MuscleGroupId) > -1 ? 'primary' : SecondaryMuscles.indexOf(+MuscleGroupId) > -1 ? 'secondary' : TertiaryMuscles.indexOf(+MuscleGroupId) > -1 ? 'tertiary' : '';

                        return (
                            <MuscleListRow className="MuscleListRow" key={MuscleGroupId}>
                                <MuscleListColumn className="MuscleListColumn">
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectMuscle_BodyMuscles({ MuscleGroupId, MuscleType: 'primary' })} Selected={UsedMuscleType === 'primary'} />
                                </MuscleListColumn>
                                <MuscleListColumn className="MuscleListColumn">
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="00C8FF" onClick={() => this.onSelectMuscle_BodyMuscles({ MuscleGroupId, MuscleType: 'secondary' })} Selected={UsedMuscleType === 'secondary'} />
                                </MuscleListColumn>
                                <MuscleListColumn className="MuscleListColumn">
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="B7E6FF" onClick={() => this.onSelectMuscle_BodyMuscles({ MuscleGroupId, MuscleType: 'tertiary' })} Selected={UsedMuscleType === 'tertiary'} />
                                </MuscleListColumn>
                                <MuscleListColumn className="MuscleListColumn" Left>
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectMuscle_BodyMuscles({ MuscleGroupId })} Text={MuscleName} TextAlign="left" />
                                </MuscleListColumn>
                            </MuscleListRow>
                        );
                    })
                }
            </MusclesListContainerSection>
        );
    }

    renderPlane = () => {
        var { t } = this.props;
        var { CustomExerciseSettings: { Plane } } = this.props;
        var { Plane: StatePlane } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_exercise_select_blank_used', { Item: t('_plane').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="Plane">
                    {
                        Plane.map(({ label: PlaneName, value: PlaneId }) => {
                            var SelectedIndex = StatePlane.indexOf(+PlaneId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={PlaneId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectPlane({ PlaneId, PlaneIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectPlane({ PlaneId, PlaneIndex: SelectedIndex })} Text={PlaneName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderStartPosition = () => {
        var { t } = this.props;
        var { CustomExerciseSettings: { StartPosition } } = this.props;
        var { StartPosition: StateStartPosition } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_exercise_select_blank_used', { Item: t('_startposition').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="StartPosition">
                    {
                        StartPosition.map(({ label: StartPositionName, value: StartPositionId }) => {
                            var SelectedIndex = StateStartPosition.indexOf(+StartPositionId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={StartPositionId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectStartPosition({ StartPositionId, StartPositionIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectStartPosition({ StartPositionId, StartPositionIndex: SelectedIndex })} Text={StartPositionName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderTags = () => {
        var { t } = this.props;
        var { CustomExerciseSettings: { Tags } } = this.props;
        var { Tags: StateTags } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_exercise_select_blank_used', { Item: t('_tags').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="Tags">
                    {
                        Tags.map(({ label: TagName, value: TagId }) => {
                            var SelectedIndex = StateTags.indexOf(+TagId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={TagId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectTag({ TagId, TagIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectTag({ TagId, TagIndex: SelectedIndex })} Text={TagName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderTrainingFocus = () => {
        var { t } = this.props;
        var { CustomExerciseSettings: { TrainingFocus } } = this.props;
        var { TrainingFocus: StateTrainingFocus } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_exercise_select_blank_used', { Item: t('_trainingfocus').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="TrainingFocus">
                    {
                        TrainingFocus.map(({ label: TrainingFocusName, value: TrainingFocusId }) => {
                            var SelectedIndex = StateTrainingFocus.indexOf(+TrainingFocusId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={TrainingFocusId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectTrainingFocus({ TrainingFocusId, TrainingFocusIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectTrainingFocus({ TrainingFocusId, TrainingFocusIndex: SelectedIndex })} Text={TrainingFocusName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderTrainingForm = () => {
        var { t } = this.props;
        var { CustomExerciseSettings: { TrainingForm } } = this.props;
        var { TrainingForm: StateTrainingForm } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_exercise_select_blank_used', { Item: t('_trainingform').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="TrainingForm">
                    {
                        TrainingForm.map(({ label: TrainingFormName, value: TrainingFormId }) => {
                            var SelectedIndex = StateTrainingForm.indexOf(+TrainingFormId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={TrainingFormId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectTrainingForm({ TrainingFormId, TrainingFormIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectTrainingForm({ TrainingFormId, TrainingFormIndex: SelectedIndex })} Text={TrainingFormName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }
    
    render() {
        if (this.state.Loading || this.props.TryingGetCreatePrivateExerciseSettings) return <Loading />;
        if (this.props.TryingGetCreatePrivateExerciseSettingsError) return null;

        var { t } = this.props;
        var { TryingInsertEditPrivateExercise, UploadProgress, UserDetails } = this.props;
        var { ExerciseId, ExerciseType, MuscleUsage, Translations } = this.state;

        var Breadcrumbs = [{ Name: t('ptadmin_navigation_library'), LinkURL: 'library-workouts', Props: true, Search: '?tab=exercises' }, { Name: t(ExerciseId ? 'WorkoutPlan_Edit_private_exercise' : 'WorkoutPlan_Create_private_exercise') }];
        var SubmitButtonProps = {
            Disabled: !ExerciseType || !MuscleUsage.PrimaryMuscles.length,
            OnClick: this.onInsertEditExercise,
            Title: t(ExerciseId ? 'ptadmin_clientnote_options_save' : 'WorkoutPlan_Create_private_exercise'),
        }

        return (
            <>
                {TryingInsertEditPrivateExercise && <Loading />}

                <Page renderBreadcrumbs={Breadcrumbs} renderSubmitButton={SubmitButtonProps}>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t(ExerciseId ? 'WorkoutPlan_Edit_private_exercise' : 'WorkoutPlan_Create_private_exercise')} TextAlign="left" />

                    <Spacer Size="medium" />

                    <TranslationsComponent
                        ContentType="Exercise"
                        DefaultLanguageId={Translations.DefaultLanguageId || UserDetails.LanguageId}
                        Languages={Translations.Languages}
                        OnAddLanguage={this.onAddLanguage}
                        OnChangeDefault={this.onChangeDefault}
                        OnChangeLanguage={this.onChangeLanguage}
                        OnChangeTranslation={this.onChangeTranslation}
                        OnDeleteLanguage={this.onDeleteLanguage}
                        Translations={Translations.Translations}
                    />

                    <Spacer Size="medium" />

                    {this.renderExerciseTypes()}

                    <Spacer Size="medium" />

                    {this.renderExerciseMedia()}

                    <Spacer Size="medium" />

                    {this.renderMuscles()}

                    <Spacer Size="medium" />

                    {this.renderJoints()}

                    <Spacer Size="medium" />

                    {this.renderEquipment()}

                    <Spacer Size="medium" />

                    {this.renderMovement()}

                    <Spacer Size="medium" />

                    {this.renderPlane()}

                    <Spacer Size="medium" />

                    {this.renderStartPosition()}

                    <Spacer Size="medium" />

                    {this.renderTrainingFocus()}

                    <Spacer Size="medium" />

                    {this.renderTrainingForm()}

                    <Spacer Size="medium" />

                    {this.renderTags()}

                    {
                        UploadProgress.CustomExercise && Object.entries(UploadProgress.CustomExercise).length > 0 && 
                            <>
                                <Spacer Size="medium" />

                                <UploadProgressComponent UploadingItems={UploadProgress.CustomExercise} />
                            </>
                    }
                </Page>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserDetails: state.Auth.UserDetails,

        CustomExerciseSettings: state.Exercise.CustomExerciseSettings,

        TryingGetCreatePrivateExerciseSettings: state.Exercise.TryingGetCreatePrivateExerciseSettings,
        TryingGetCreatePrivateExerciseSettingsError: state.Exercise.TryingGetCreatePrivateExerciseSettingsError,
        TryingInsertEditPrivateExercise: state.Exercise.TryingInsertEditPrivateExercise,
        TryingInsertEditPrivateExerciseError: state.Exercise.TryingInsertEditPrivateExerciseError,

        ExerciseToExerciseDetails: state.SaveData.ExerciseToExerciseDetails,

        UploadProgress: state.UploadContent.UploadProgress
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearPrivateExerciseSettings, GetPreSignedS3Url, InsertEditPrivateExercise, SaveDataInProps, UploadToPresignedUrl, ViewCreatePrivateExerciseSettings } )(Exercise));
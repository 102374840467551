import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearClientDetails, ClearExerciseDetail, GetExerciseDetail, GetExerciseTrackingHistory, InsertEditExerciseGoal } from '../../Services/Actions';
import history from '../../history';

import moment from 'moment';

import  { AccordionsContainer, ChartContainer, Container, DetailContainer, EditGoalContainer, ExerciseImageContainer, HeaderContainer, LineGraphContainer, ProgressContainer, ProgressStatsContainer, ProgressStatsContainerInner, StyledBodyMuscles, StyledExerciseImage, StyledGoal, StyledInputLabelSideBySide, StyledLineBar, StyledLoadMore, TrackingHistoryContainer, TrackingHistoryRow, ViewCount } from './styles';

import Accordion from '../../Components/Accordion';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import LineGraph from '../../Components/Charts/LineGraph';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import EditIcon from '../../Assets/Icons/Edit_Dark.png';

import { SecondsToMinutesAndSeconds, SecondsToTime } from '../../Functions';

class ExerciseDetail extends React.PureComponent {
    _isMounted = false;

    state = {
        ActiveSearch: false,
        Goal: 0,
        GoalMinutes: 0,
        GoalSeconds: 0,
        Loading: true,
        ShowEditGoalModal: false,
        PageNo: 1,
        PageSize: 50
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientId, ExerciseId } = this.props.match.params;

        if (!+ClientId || !+ExerciseId) history.push('/');
        else {
            this.props.GetExerciseDetail({ ClientId, ExerciseId }).then(() => {
                if (this.props.TryingGetExerciseDetailError) history.push('/clients');
                else {
                    var { ExerciseType, Progress: { Goal } } = this.props.ExerciseDetail;

                    if (+ExerciseType === 2) {
                        var { Minutes: GoalMinutes, Seconds: GoalSeconds } = SecondsToMinutesAndSeconds(Goal);
    
                        GoalSeconds = +GoalSeconds === 0 ? '00' : +GoalSeconds < 10 ? `0${GoalSeconds}` : GoalSeconds;
    
                        this.setState({ Goal, GoalMinutes, GoalSeconds });
                    }

                    this.setState({ Loading: false });
                }
            });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        
        this.props.ClearClientDetails();
        this.props.ClearExerciseDetail();
    }

    onBlurGoal = () => {
        var { Goal } = this.state;

        if (+Goal === 0) this.setState({ Goal: 0 });
    }

    onBlurGoalTime = () => {
        var Goal = 0;
        Goal += (this.state.GoalMinutes * 60) + +this.state.GoalSeconds;

        var { Minutes: GoalMinutes, Seconds: GoalSeconds } = SecondsToMinutesAndSeconds(Goal);

        GoalSeconds = +GoalSeconds === 0 ? '00' : +GoalSeconds < 10 ? `0${GoalSeconds}` : GoalSeconds;
        GoalMinutes = +GoalMinutes === 0 ? 0 : GoalMinutes;

        this.setState({ Goal, GoalMinutes, GoalSeconds });
    }

    onChangeGoal = event => {
        this.setState({ Goal: event.target.value });
    }

    onChangeTime = (event, TimeType, OtherTimeTypeValue) => {
        var Goal = 0;
        if (TimeType === 'Minutes') Goal += (event.target.value * 60) + OtherTimeTypeValue;
        else if (TimeType === 'Seconds') Goal += event.target.value + (OtherTimeTypeValue * 60);

        this.setState({ [`Goal${TimeType}`]: event.target.value, Goal });
    }

    onInsertEditExerciseGoal = () => {
        var { ClientId } = this.props.match.params;
        var { ExerciseId, ExerciseType, Progress: { Goal: CurrentGoal } } = this.props.ExerciseDetail;
        var { Goal, PageNo } = this.state;

        if (+Goal && +Goal !== +CurrentGoal) {
            var Distance, Reps, Seconds, Weight;
            if (+ExerciseType === 1) Reps = +Goal;
            else if (+ExerciseType === 2) Seconds = +Goal;
            else if (+ExerciseType === 3) Distance = +Goal;
            else if (+ExerciseType === 4 || +ExerciseType === 5) Weight = +Goal;

            // Reset Tracking History
            PageNo = 1;

            this.props.InsertEditExerciseGoal({ ClientId, Distance, ExerciseId, ExerciseType, Reps, Seconds, Weight });
        }

        this.onToggleShowEditGoalModal({ PageNo, ShowEditGoalModal: false });
    }

    onLoadTrackingHistory = Increment => {
        var { PageNo, PageSize } = this.state;
        var { ExerciseId, ExerciseType } = this.props.ExerciseDetail;
        var { ClientId } = this.props.match.params;

        if (Increment) PageNo = PageNo + 1;

        this.setState({ ActiveSearch: true, PageNo }, () => this.props.GetExerciseTrackingHistory({ AddToExisting: Increment, ClientId, ExerciseId, ExerciseType, TrackingPageNo: PageNo, TrackingPageSize: PageSize }).then(() => this.setState({ ActiveSearch: false })));
    }

    onToggleShowEditGoalModal = ({ Goal = 0, ShowEditGoalModal }) => {
        if (ShowEditGoalModal) Goal = this.props.ExerciseDetail.Progress.Goal;
        this.setState({ Goal, ShowEditGoalModal });
    }

    renderAccordions = () => {
        return (
            <AccordionsContainer>
                {this.renderInstructions()}

                {/* <Spacer Size="extra-extra-small" /> */}
                
                {this.renderMusclesUsed()}
            </AccordionsContainer>
        )
    }

    renderChart = () => {
        var { t } = this.props;
        var { Chart, ExerciseType, Units } = this.props.ExerciseDetail;

        return (
            <ChartContainer>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('graph_to_show_progress')} TextAlign="center" />
                
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('graph_shows_alltime_maxes')} TextAlign="center" />

                <Spacer Size="small" />

                <LineGraphContainer>
                    <LineGraph
                        AllTime
                        Goals={Chart.Goals}
                        Increment={Chart.Increment}
                        IsTime={+ExerciseType === 2}
                        Maximum={Chart.Maximum}
                        Minimum={Chart.Minimum}
                        Measurements={Chart.Measurements}
                        TimePeriod="1M"
                        Units={Units}
                    />
                </LineGraphContainer>
            </ChartContainer>
        );
    }

    renderInstructions = () => {
        var { t } = this.props;
        var { ExerciseInstructions } = this.props.ExerciseDetail;

        return (
            <Accordion
                BackgroundColor="var(--white)"
                BorderColor="var(--white-concrete)"
                Text={t('instructions')}
            >
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={ExerciseInstructions} TextAlign="left" WhiteSpace="pre-line" />
            </Accordion>
        );
    }

    renderMusclesUsed = () => {
        var { t } = this.props;
        var { PrimaryMuscleIds, PrimaryMuscleNames, SecondaryMuscleIds, SecondaryMuscleNames } = this.props.ExerciseDetail.UsedMuscles;

        return (
            <Accordion
                BackgroundColor="var(--white)"
                BorderColor="var(--white-concrete)"
                Text={t('WorkoutPlan_Muscles_used')}
            >
                <StyledBodyMuscles
                    PrimaryMuscleIds={PrimaryMuscleIds}
                    SecondaryMuscleIds={SecondaryMuscleIds}
                />

                <MultiText
                    ContainerWidth="100%"
                    TextAlign="left"
                    Texts={[
                        { FontColor: 'blue-abel', FontFamily: 'semibold', FontSize: 'medium', Text: `${t('WorkoutPlan_Primary')} -` },
                        { FontColor: 'blue-abel', FontFamily: 'medium', FontSize: 'medium', Text: PrimaryMuscleNames.join(', ') }
                    ]}
                />

                {
                    SecondaryMuscleIds && SecondaryMuscleIds.length > 0 &&
                    <MultiText
                        ContainerWidth="100%"
                        TextAlign="left"
                        Texts={[
                            { FontColor: 'blue-light', FontFamily: 'semibold', FontSize: 'medium', Text: `${t('WorkoutPlan_Secondary')} -` },
                            { FontColor: 'blue-light', FontFamily: 'medium', FontSize: 'medium', Text: SecondaryMuscleNames.join(', ') }
                        ]}
                    />
                }
            </Accordion>
        );
    }

    renderProgress = () => {
        var { t } = this.props;
        var { ExerciseDetail: { ExerciseType, Progress: { CompletionStatus, Current, Goal, ProgressLine, Starting, Units } } } = this.props;

        var Buttons = [{ FontFamily: 'semibold', Icon: EditIcon, IconPosition: 'left', OnClick: () => this.onToggleShowEditGoalModal({ ShowEditGoalModal: true }), Title: t('edit_goal') }];

        return (
            <>
                <DetailContainer>
                    <PageText FontFamily="semibold" FontSize="medium-2" NoMargin Text={`${t('ptadmin_contenttype_header_progress')} (${Units})`} TextAlign="center" />

                    <Spacer Size="small" />

                    {
                        Starting !== null ?
                        <>
                            {this.renderStats()}

                            {
                                !!+Goal &&
                                <>
                                    <Spacer Size="extra-small" />

                                    <StyledLineBar
                                        CompletionStatus={CompletionStatus}
                                        CurrentValue={Current}
                                        GoalMax={0}
                                        GoalMin={Goal}
                                        HideCurrent
                                        IsTime={+ExerciseType === 2}
                                        ProgressLine={ProgressLine}
                                        Units={Units}
                                    />
                                </>
                            }

                            <Spacer Size="small" />
        
                            <ButtonGroup Buttons={Buttons} ButtonWidth="fit-content" />
                        </>
                    :
                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('WorkoutPlan_This_is_a_new_exercise')} TextAlign="center" />
                    }
                </DetailContainer>
            </>
        );
    }

    renderShowEditGoalModal = () => {
        var { t } = this.props;
        var { Goal: CurrentGoal } = this.props.ExerciseDetail.Progress;
        var { Goal, ShowEditGoalModal } = this.state;

        var ActionText = !!+CurrentGoal ? t('save_with_first_char_uppercase') : t('ptadmin_clientnote_options_add');

        if (ShowEditGoalModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={!+Goal}
                    BottomButtonOnClick={this.onInsertEditExerciseGoal}
                    BottomButtonText={ActionText}
                    InformationRenderer={this.renderShowEditGoalModalInfo}
                    OnHide={() => this.onToggleShowEditGoalModal({ ShowEditGoalModal: false })}
                    Show={ShowEditGoalModal}
                    Size="medium"
                    TextAlign="center"
                />
            );
        }
    }

    renderShowEditGoalModalInfo = () => {
        var { t } = this.props;
        var { ExerciseType, Progress: { Goal: CurrentGoal }, Units } = this.props.ExerciseDetail;
        var { Goal, GoalMinutes, GoalSeconds } = this.state;

        var TitleText = !!+CurrentGoal ? t('edit_goal') : t('add_goal');

        return (
            <EditGoalContainer>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={TitleText} TextAlign="center" />

                <Spacer Size="small" />

                {
                    !!+CurrentGoal &&
                    <>
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('current_goal')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${CurrentGoal} ${Units}`} TextAlign="center" />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('new_goal')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />
                    </>
                }

                {
                    ExerciseType === 2 ?
                    <StyledInputLabelSideBySide
                        InputFontFamily="semibold"
                        InputFontSize="medium-3"
                        LabelFontFamily="medium"
                        LabelFontSize="medium-1"
                        Label={`${t('min_str').toLowerCase()} / ${t('Seconds_short').toLowerCase()}`}
                        OnBlur={this.onBlurGoalTime}
                        OnChange={(event, TimeType, OtherTimeTypeValue) => this.onChangeTime(event, TimeType, OtherTimeTypeValue)}
                        Placeholder="0"
                        TimeValueMinutes={GoalMinutes}
                        TimeValueSeconds={GoalSeconds}
                        Type="time"
                    />
                :
                    <StyledGoal
                        FontColor="blue-astronaut"
                        FontFamily="semibold"
                        FontSize="medium-3"
                        NoLabel
                        NoMargin
                        OnBlur={this.onBlurGoal}
                        OnChange={this.onChangeGoal}
                        Placeholder="0"
                        Size="medium"
                        Type="text"
                        Value={Goal}
                    />
                }
            </EditGoalContainer>
        );
    }

    renderStats = () => {
        var { t } = this.props;
        var { ExerciseDetail: { ExerciseType, Progress: { Current, Goal, Starting } } } = this.props;

        var CurrentText = `${Current}`;
        var GoalText = `${Goal}`;
        var StartingText = `${Starting}`;

        if (ExerciseType === 2) {
            CurrentText = SecondsToTime(Current);
            GoalText = SecondsToTime(Goal);
            StartingText = SecondsToTime(Starting);
        }

        return (
            <ProgressStatsContainer>
                <ProgressStatsContainerInner>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Starting')} TextAlign="center" />

                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={StartingText} TextAlign="center" />
                </ProgressStatsContainerInner>

                <ProgressStatsContainerInner>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('WorkoutPlan_Current')}`} TextAlign="center" />

                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={CurrentText} TextAlign="center" />
                </ProgressStatsContainerInner>

                <ProgressStatsContainerInner>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('Goal')}`} TextAlign="center" />

                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={GoalText} TextAlign="center" />
                </ProgressStatsContainerInner>
            </ProgressStatsContainer>
        );
    }

    renderTrackingHistory = () => {
        var { t } = this.props;
        var { ExerciseDetail: { ExerciseType, TrackingHistory: { IsMoreResults, TotalRecords, TrackingHistory } }, TryingGetExerciseTrackingHistory } = this.props;
        var { ActiveSearch, PageNo, PageSize } = this.state;

        var ColumnCount = [ 1, 2, 3 ].includes(ExerciseType) ? 3 : 4;

        var Column1Text = '';
        var Column2Text = '';

        if ([ 1, 4 ].includes(ExerciseType)) Column1Text = t('Reps');
        if ([ 2, 5 ].includes(ExerciseType)) Column1Text = t('Seconds');
        if ([ 3 ].includes(ExerciseType)) Column1Text = t('Distance');
        if ([ 4 ].includes(ExerciseType)) Column2Text = t('Weight');
        if ([ 5 ].includes(ExerciseType)) Column2Text = t('Weight');

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ProgressSection_Tracking_history')} TextAlign="center" />

                <Spacer Size="small" />

                <ViewCount>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`1 - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords}`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('measurements').toLowerCase()} />
                </ViewCount>

                <Spacer Size="small" />

                <TrackingHistoryContainer>
                    <TrackingHistoryRow ColumnCount={ColumnCount}>
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('Date_str')} TextAlign="center" />
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={Column1Text} TextAlign="center" />
                        {
                            ColumnCount === 4 &&
                            <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={Column2Text} TextAlign="center" />
                        }
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('ProgressSection_CalculatedMax')} TextAlign="center" />
                    </TrackingHistoryRow>

                    <StyledLoadMore
                        ActiveSearch={ActiveSearch}
                        CompletedFirstSearch
                        IsLoading={TryingGetExerciseTrackingHistory}
                        ItemDescriptionType={t('measurements').toLowerCase()}
                        ItemId="Id"
                        ItemName="CalculatedMax"
                        ItemRenderer={this.renderTrackingHistoryList}
                        Items={TrackingHistory}
                        HasMoreItems={!!+IsMoreResults}
                        HideViewCount
                        LoadMore={() => this.onLoadTrackingHistory(true)}
                        NoItemsText={t('search_noresults')}
                        PageNo={PageNo}
                        PageSize={PageSize}
                        TotalRecords={TotalRecords}
                    />
                </TrackingHistoryContainer>
            </>
        );
    }

    renderTrackingHistoryList = ({ Item: { CalculatedMax, Id, Distance, Reps, Seconds, Units, UpdateDate, Weight } }) => {
        var { ExerciseType } = this.props.ExerciseDetail;
        
        var ColumnCount = [ 1, 2, 3 ].includes(ExerciseType) ? 3 : 4;
        var Column1Value = null;
        var Column2Value = null;
        var CalculatedMaxText = '';

        if ([ 1, 4 ].includes(ExerciseType)) Column1Value = `${Reps}`;
        if ([ 2, 5 ].includes(ExerciseType)) Column1Value = SecondsToTime(Seconds);
        if ([ 3 ].includes(ExerciseType)) Column1Value = `${Distance} ${Units.toLowerCase()}`;
        if ([ 4 ].includes(ExerciseType)) Column2Value = `${Weight} ${Units.toLowerCase()}`;
        if ([ 5 ].includes(ExerciseType)) Column2Value = `${Weight} ${Units.toLowerCase()}`;

        if ([ 2 ].includes(ExerciseType)) CalculatedMaxText = SecondsToTime(CalculatedMax);
        else CalculatedMaxText = `${CalculatedMax} ${Units.toLowerCase()}`;

        return (
            <TrackingHistoryRow key={Id} ColumnCount={ColumnCount}>
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={moment(UpdateDate).format('[NumbersDate]')} TextAlign="center" />
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={Column1Value} TextAlign="center" />
                {
                    ColumnCount === 4 &&
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={Column2Value} TextAlign="center" />
                }
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={CalculatedMaxText} TextAlign="center" />
            </TrackingHistoryRow>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingGetExerciseDetail) return <Loading />;

        var { Device, ClientDetails: { ClientId, DisplayName }, ExerciseDetail: { ExerciseImage, ExerciseName, Progress: { Starting } }, TryingInsertEditExerciseGoal } = this.props;

        return (
            <>
                {TryingInsertEditExerciseGoal && <Loading />}

                <Page
                    renderBreadcrumbs={[
                        { Name: `${DisplayName}`, LinkURL: `client/${ClientId}`, Props: { SelectedTab: 'Progress' }},
                        { Name: ExerciseName}
                    ]}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={ExerciseName} />

                    <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />
                    
                    <HeaderContainer>
                        <ExerciseImageContainer>
                            <StyledExerciseImage ImageSrc={ExerciseImage || ''} SelfLoading />
                        </ExerciseImageContainer>
                        <ProgressContainer>
                            {this.renderProgress()}
                        </ProgressContainer>
                    </HeaderContainer>                

                    <Spacer Size="medium" />

                    <Container>
                        {this.renderAccordions()}

                        {
                            !!+Starting &&
                            <>
                                <Spacer Size="medium" />

                                {this.renderChart()}

                                <Spacer Size="medium" />

                                {this.renderTrackingHistory()}
                            </>
                        }
                    </Container>
                </Page>

                {this.renderShowEditGoalModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        ClientDetails: state.Client.ClientDetails,

        ExerciseDetail: state.Exercise.ExerciseDetail,

        TryingGetExerciseDetail: state.Exercise.TryingGetExerciseDetail,
        TryingGetExerciseDetailError: state.Exercise.TryingGetExerciseDetailError,
        TryingGetExerciseTrackingHistory: state.Exercise.TryingGetExerciseTrackingHistory,
        TryingGetExerciseTrackingHistoryError: state.Exercise.TryingGetExerciseTrackingHistoryError,

        TryingInsertEditExerciseGoal: state.Progress.TryingInsertEditExerciseGoal,
        TryingInsertEditExerciseGoalError: state.Progress.TryingInsertEditExerciseGoalError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearClientDetails, ClearExerciseDetail, GetExerciseDetail, GetExerciseTrackingHistory, InsertEditExerciseGoal } )(ExerciseDetail));
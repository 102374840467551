import React from 'react';
import { withTranslation } from 'react-i18next';
// import { toast } from 'react-toastify';
// import moment from 'moment';

import * as data from './data';
import * as styles from './styles';

import Loading from '../../Components/Loading';
import PaginationContainer from '../../Components/PaginationCounter';

// import ButtonGroup from '../../Components/Buttons/ButtonGroup';
// import MultiText from '../../Components/Text/MultiText';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';
import Spacer from '../../Components/Spacer';

import DownArrow from '../../Assets/Icons/DownArrow.png';
import UpArrow from '../../Assets/Icons/UpArrow.png';

import { DynamicSort, PaginateArray } from '../../Functions';

const LoadingDelayInMilliseconds = 0;

const EMPLOYEES_TABLE_COLUMNS = [
    { Id: 1, IsInt: 1, SortCol: 'UserId', Title: 'User Id' },
    { Id: 2, IsInt: 0, SortCol: 'FullName', Title: 'Full Name' },
    { Id: 3, IsInt: 0, SortCol: 'Email', Title: 'Email' }
]

class DeliverySite extends React.Component {
    state = {
        CopiedLists: {
            Employees: data.Dashboard.Employees
        },
        Employees: {
            EmployeesList: [],
            NewSearch: false,
            PageNo: 1,
            PageSize: 30,
            Sorting: {
                SortCol: '',
                SortDir: 'ASC'
            },
            TotalPages: Math.floor(data.Dashboard.Employees.length / 30)
        },
        Loading: false
    }

    componentDidMount() {
        this.onLoadEmployees();
    }

    onLoadEmployees = Increment => {
        var { NewSearch, PageNo, PageSize } = this.state.Employees;

        if (NewSearch) PageNo = 1;
        else if (Increment) PageNo++;

        var EmployeesList = PaginateArray({ ArrayList: this.state.CopiedLists.Employees, PageNo, PageSize });

        this.setState({ Loading: true }, () => {
            setTimeout(() => {
                this.setState({ Employees: { ...this.state.Employees, EmployeesList, PageNo }, Loading: false });
            }, LoadingDelayInMilliseconds);
        });
    }

    onPaginateEmployees = ({ NewPageNo }) => {
        if (this.state.Employees.PageNo !== NewPageNo) this.setState({ Employees: { ...this.state.Employees, PageNo: NewPageNo } }, () => this.onLoadEmployees());
    }

    onToggleSorting = ({ IsInt, SortCol: NewSortCol }) => {
        if (!this.state.CopiedLists.Employees.length) return null;

        var { SortCol, SortDir } = this.state.Employees.Sorting;

        // SortDir Goes From ASC --> DESC --> Nothing

        // Toggle Sorting Of Already Sorted Column
            if (SortCol === NewSortCol) {
                if (SortDir === 'ASC') SortDir = 'DESC';
                else if (SortDir === 'DESC') {
                    SortDir = '';
                    SortCol = '';
                }
                else if (SortDir === '') SortDir = 'ASC';
            }
        // Start Sorting On New Column
            else {
                SortCol = NewSortCol;
                SortDir = 'ASC';
            }

        // Sort and Add Empty Object In Again
            var NewEmployees = [ ...this.state.CopiedLists.Employees.sort(DynamicSort({ IsInt, SortCol, SortDir })) ];

        this.setState({ CopiedLists: { ...this.state.CopiedLists, Employees: NewEmployees }, Employees: { ...this.state.Employees, Sorting: { SortCol, SortDir } } }, () => this.onLoadEmployees());
    }

    renderEmployees = () => {
        var { EmployeesList, PageNo, Sorting: { SortCol: StateSortCol, SortDir: StateSortDir }, TotalPages } = this.state.Employees;

        return (
            <div>
                {this.renderViewCount_Employees()}

                <Spacer Size="small" />

                <PaginationContainer OnPaginate={this.onPaginateEmployees} PageNo={PageNo} TotalPages={TotalPages} />

                <Spacer Size="medium" />

                <styles.UsersContainer className="UsersContainer">
                    <styles.UsersTableContainer className="UsersTableContainer">
                        <styles.UsersTable className="UsersTable">
                            <styles.UsersTableHeader className="UsersTableHeader">
                                <styles.UsersTableHeaderRow className="UsersTableHeaderRow">
                                    {
                                        EMPLOYEES_TABLE_COLUMNS.map(({ Id, IsInt, SortCol, Title }) => {
                                            return (
                                                <styles.UsersTableHeaderCell className="UsersTableHeaderCell" key={Id} onClick={() => this.onToggleSorting({ IsInt, SortCol })}>
                                                    <styles.UsersTableHeaderCellInnerDiv className="UsersTableHeaderCellInnerDiv">
                                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Title} />
                                                        {StateSortCol === SortCol && <styles.SortingIcon ImageSrc={StateSortDir === 'DESC' ? DownArrow : UpArrow} />}
                                                    </styles.UsersTableHeaderCellInnerDiv>
                                                </styles.UsersTableHeaderCell>
                                            );
                                        })
                                    }
                                </styles.UsersTableHeaderRow>
                            </styles.UsersTableHeader>
                            <styles.UsersTableBody className="UsersTableBody">
                                {
                                    EmployeesList.map((User, UserIndex) => {
                                        var { DisplayName, ProfilePhotoUrl, Sex, UserId } = User;
                                        return (
                                            <styles.UsersTableBodyRow className="UsersTableBodyRow" key={UserId} onClick={() => this.onOpenUserDetailsModal({ User, UserIndex })}>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {UserId}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell" Flex>
                                                    <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                                                        <ProfilePhoto Sex={Sex || 'Male'} Size="small-3" Source={ProfilePhotoUrl || ''} />
                                                    </styles.ProfilePhotoContainer>
                                                    {DisplayName}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    
                                                </styles.UsersTableBodyCell>
                                            </styles.UsersTableBodyRow>
                                        )
                                    })
                                }
                            </styles.UsersTableBody>
                        </styles.UsersTable>
                    </styles.UsersTableContainer>
                </styles.UsersContainer>
            </div>
        );
    }

    renderViewCount_Employees = () => {
        var { t } = this.props;

        var TotalRecords = this.state.CopiedLists.Employees.length;
        var { PageNo, PageSize } = this.state.Employees;

        if (!TotalRecords) TotalRecords = 0;

        return (
            <div className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${!TotalRecords ? 0 : ((PageNo - 1) * PageSize) + 1} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords} employees`} />
            </div>
        )
    }

    render() {
        return (
            <>
                {this.state.Loading && <Loading />}

                <Page NoMargin>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text="Delivery Site" TextAlign="center" />

                    <Spacer Size="medium" />

                    {this.renderEmployees()}
                </Page>
            </>
        )
    }
}

export default withTranslation()(DeliverySite);
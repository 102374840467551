import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  AutoCompleteWorkout,
  ClearClientDetails,
  ClearSingleVideoSession,
  ClearWorkoutDetail,
  DeleteWorkout,
  FreezeWorkoutForPrint,
  GetSingleVideoSessionDetails,
  GetWorkoutNotes,
  GetWorkoutDetail,
  InsertEditWorkoutNote,
  SaveDataInProps,
  UpdateSelectedTab,
  UpdateWorkoutInformation,
  UploadToPresignedUrl,
} from "../../Services/Actions";
import moment from "moment";
import history from "../../history";

import {
  ButtonIcon,
  ChartContainer,
  DurationContainer,
  DurationInnerContainer,
  MediaPreview,
  MultiSelect,
  StyledDuration,
  StyledEditWorkoutNameIcon,
  StyledExternalLink,
  StyledMediaDocumentPreview,
  StyledMediaPreview,
  StyledVideoPreview,
  StyledVideoScreenIcon,
  StyledWorkoutImage,
  StyledWorkoutNameInput,
  StyledWorkoutNoteTextArea,
  UploadContainer,
  UploadButtonsContainer,
  UploadButtonsRow,
  UploadDocumentsContainer,
  WorkoutVideoSessionContainer,
  WorkoutNoteContainer,
  WorkoutNotesContainer,
  WorkoutNotesContainerInner,
  WorkoutFrozenStatusContainer,
  WorkoutFrozenContainer,
  StyledFrozenWorkoutIcon,
  WorkoutFrozen,
  UnfreezeTextContainer,
} from "./styles";
import * as styles from "./styles";

import AdditionalActivity from "../../Components/AdditionalActivity2";
import EnduranceWorkout from "../../Components/EnduranceWorkout";
import HeartRateLineGraph from '../../Components/Charts/HeartRate';
import HeartRateZoneChart from '../../Components/Charts/HeartRateZoneChart';
import Loading from "../../Components/Loading";
import MultiText from "../../Components/Text/MultiText";
import Page from "../../Components/Page";
import PageText from "../../Components/Text/PageText";
import Spacer from "../../Components/Spacer";
import StrengthWorkout from "../../Components/StrengthWorkout";
import CreateVideoSession from "../../Components/ScheduleVideoSession/CreateSession";
import GetVideoSessions from "../../Components/ScheduleVideoSession/GetSession";
import UploadProgressComponent from "../../Components/UploadProgress";

import ChooseDates from "../../Modals/ChooseDates";
import Info from "../../Modals/Info";
import PreviewMediaModal from "../../Modals/PreviewMedia";
import ExportWorkoutPdf from "../../Modals/ExportWorkoutPdf/index";

import Camera from "../../Assets/Icons/Camera.png";
import PencilGray from "../../Assets/Icons/Pencil_Gray_Filled.png";
import PDF from "../../Assets/Icons/PDF.png";
import DownloadGray from "../../Assets/Icons/Download_Gray.png";
import FreezeWorkoutSnowImage from "../../Assets/Icons/Snowflake.png";
import Screen from "../../Assets/Icons/Screen.png";

import {
  CreateUploadToPresignedUrlPromise,
  getFileExtension,
} from "../../Functions";

class WorkoutDetail extends React.Component {
  state = {
    ClientFileSize: null,
    DeleteMedia_Client: [],
    DeleteMedia_Trainer: [],
    Duration: "",
    EditingWorkoutName: false,
    isEditSession: false,
    IsWorkoutFrozen: false,
    IsVideoSessionDeleted: false,
    ImageTooLarge: false,
    Loading: true,
    MaxFileSizesKB: 10240,
    MediaTooLarge_Client: 0,
    MediaTooLarge_Trainer: 0,
    OriginalWorkoutDetails: {},
    PreviewMedia: null,
    PreviewMediaFileType: null,
    PreviewMediaUrl: "",
    SelectedImage: null,
    SelectedMedia_Client: [],
    SelectedMedia_Trainer: [],
    ShowChooseDatesModal: false,
    ShowCreateVideoSession: false,
    ShowDeleteWorkoutModal: false,
    ShowExportWorkoutModal: false,
    ShowFullscreenMediaPreview: false,
    TrainerFileSize: null,
    UploadingErrors: { NoteType: null, Files: [] },
    UploadMedia_Client: [],
    UploadMedia_Trainer: [],
    WorkoutDetails: {},
    WrongImageType: false,
  };

  componentDidMount() {
    this._isMounted = true;

    var { WorkoutId } = this.props.match.params;
    WorkoutId = +WorkoutId;

    this.props.GetWorkoutDetail({ Rebuild: 0, WorkoutId }).then(() => {
      if (this._isMounted && !this.props.TryingGetWorkoutDetailError) {
        var {
          ActivityId,
          AssignedDate,
          ClientFileSize,
          ClientNote,
          Duration,
          TrainerFileSize,
          TrainerNote,
          WorkoutName,
        } = this.props.WorkoutDetails;

        // Set Window History for SelectedTab = 'Workouts' and WorkoutDate = AssignedDate
        // history.push({ state: { SelectedTab: 'Workouts', WorkoutDate: AssignedDate } });
        // history.push({ pathname: this.props.location.pathname, state: { SelectedTab: 'Workouts', WorkoutDate: AssignedDate } });
        // window.history.replaceState({ SelectedTab: 'Workouts', WorkoutDate: AssignedDate }, '');

        AssignedDate = moment(
          AssignedDate.substring(0, 10) + " " + AssignedDate.substring(11, 16)
        ).format("YYYY-MM-DD HH:mm");

        document.title = `ABEL - ${this.props.ClientDetails.FirstName && this.props.ClientDetails.LastName ? `${this.props.ClientDetails.FirstName} ${this.props.ClientDetails.LastName}` : this.props.ClientDetails.Email} - ${WorkoutName}`;

        this.setState({
          ClientFileSize,
          Loading: false,
          OriginalWorkoutDetails: {
            ActivityId,
            AssignedDate,
            ClientNote,
            Duration,
            TrainerNote,
            WorkoutName,
          },
          TrainerFileSize,
          WorkoutDetails: {
            ...this.props.WorkoutDetails,
            AssignedDate,
            WorkoutId,
          },
          IsWorkoutFrozen: this.props.WorkoutDetails.PrintFrozen,
        });
      }
    });
  }

  componentDidUpdate() {
    if (this.state.IsVideoSessionDeleted) {
      this.props.ClearSingleVideoSession();
      this.onToggleShowCreateVideoSession(false);
      this.setState({ IsVideoSessionDeleted: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    document.title = "ABEL Admin";

    this.props.ClearClientDetails();
    this.props.ClearWorkoutDetail();
  }

  isSessionDeleted(IsVideoSessionDeleted) {
    this.setState({ IsVideoSessionDeleted: IsVideoSessionDeleted });
  }

  getFilteredVideoSessionInfo(VideoSessionInfo) {
    const {
      VideoSessionId,
      Date,
      Description,
      HostUrl,
      NotifyBefore,
      NotifyPeriod,
      Users,
    } = VideoSessionInfo;
    return {
      VideoSessionId,
      Date,
      Description,
      HostUrl,
      NotifyBefore,
      NotifyPeriod,
      SessionType: "workout",
      Users,
    };
  }

  onBlurWorkoutName = () => {
    this.setState({ EditingWorkoutName: false });
    if (
      this.state.WorkoutDetails.WorkoutName.trim() !==
      this.state.OriginalWorkoutDetails.WorkoutName.trim()
    )
      this.onUpdateWorkoutInformation();
  };

  onBlurDuration = () => {
    if (
      this.state.WorkoutDetails.Duration !==
      this.state.OriginalWorkoutDetails.Duration
    )
      this.onUpdateWorkoutInformation();
  };

  onChangeDuration = (event) => {
    this.setState({
      WorkoutDetails: {
        ...this.state.WorkoutDetails,
        Duration: event.target.value,
      },
    });
  };

  onChangeWorkoutName = (event) => {
    this.setState({
      WorkoutDetails: {
        ...this.state.WorkoutDetails,
        WorkoutName: event.target.value,
      },
    });
  };

  onChangeWorkoutNote = (event, NoteType) => {
    this.setState({
      WorkoutDetails: {
        ...this.state.WorkoutDetails,
        [`${NoteType}`]: event.target.value,
      },
    });
    // if (this.state.WorkoutDetails[`${NoteType}`] !== event.target.value.trim()) this.setState({ WorkoutDetails: { ...this.state.WorkoutDetails, [`${NoteType}`]: event.target.value.trim() } }, () => this.onUpdateWorkoutInformation());
  };

  onCopyWorkoutToTemplate = (WorkoutLaps) => {
    var { AdditionalActivityDetails, WorkoutDetails, WorkoutExercises } =
      this.props;

    this.props
      .SaveDataInProps({
        WorkoutToTemplateDetails: {
          AdditionalActivityDetails,
          WorkoutDetails,
          WorkoutExercises,
          WorkoutLaps,
        },
      })
      .then(() => history.push("/library/workout-template"));
  };

  onDeleteMedia = ({
    event,
    DeleteMediaIndex,
    DocumentId,
    FileSize_KB,
    IsNewMedia,
    MediaUrl,
    UserType,
  }) => {
    if (event) event.preventDefault();

    FileSize_KB =
      !!IsNewMedia || DeleteMediaIndex === -1
        ? this.state[`${UserType}FileSize`] - FileSize_KB
        : this.state[`${UserType}FileSize`] + FileSize_KB;
    FileSize_KB = Math.round(FileSize_KB * 10) / 10;

    if (!!IsNewMedia) {
      for (var i = 0; i < this.state[`SelectedMedia_${UserType}`].length; i++) {
        if (this.state[`SelectedMedia_${UserType}`][i].MediaUrl === MediaUrl) {
          var SelectedIndex = i;
          break;
        }
      }

      this.setState({
        [`MediaTooLarge_${UserType}`]: 0,
        [`SelectedMedia_${UserType}`]: [
          ...this.state[`SelectedMedia_${UserType}`].slice(0, SelectedIndex),
          ...this.state[`SelectedMedia_${UserType}`].slice(SelectedIndex + 1),
        ],
        [`UploadMedia_${UserType}`]: [
          ...this.state[`UploadMedia_${UserType}`].slice(0, SelectedIndex),
          ...this.state[`UploadMedia_${UserType}`].slice(SelectedIndex + 1),
        ],
        [`${UserType}FileSize`]: FileSize_KB,
      });
    } else {
      if (DeleteMediaIndex === -1)
        this.setState({
          [`DeleteMedia_${UserType}`]: [
            ...this.state[`DeleteMedia_${UserType}`],
            DocumentId,
          ],
          [`MediaTooLarge_${UserType}`]: 0,
          [`${UserType}FileSize`]: FileSize_KB,
        });
      else {
        if (FileSize_KB > this.state.MaxFileSizesKB)
          this.setState({ [`MediaTooLarge_${UserType}`]: FileSize_KB });
        else {
          this.setState({
            [`DeleteMedia_${UserType}`]: [
              ...this.state[`DeleteMedia_${UserType}`].slice(
                0,
                DeleteMediaIndex
              ),
              ...this.state[`DeleteMedia_${UserType}`].slice(
                DeleteMediaIndex + 1
              ),
            ],
            [`MediaTooLarge_${UserType}`]: 0,
            [`${UserType}FileSize`]: FileSize_KB,
          });
        }
      }
    }
  };

  onDeleteWorkout = () => {
    var { ClientId } = this.props.ClientDetails;
    var { WorkoutId } = this.props.match.params;

    this.onToggleShowDeleteWorkoutModal(false);
    this.props.DeleteWorkout({ ClientId, WorkoutId }).then(() => {
      if (this._isMounted && !this.props.TryingDeleteWorkoutError) {
        history.push({
          pathname: `/client/${ClientId}`,
          state: {
            SelectedTab: "Workouts",
            WorkoutDate: this.state.WorkoutDetails.AssignedDate,
          },
        });
      }
    });
  };

  onToggleEditSession() {
    this.setState({ isEditSession: !this.state.isEditSession });
  }

  onMarkCompleted = () => {
    var {
      ClientDetails: { ClientId },
    } = this.props;
    var {
      WorkoutDetails: { WorkoutId },
    } = this.state;

    return this.props
      .AutoCompleteWorkout({ ClientId, WorkoutId })
      .then(({ Response, WorkoutCompleted }) => {
        if (Response)
          this.setState({
            WorkoutDetails: {
              ...this.state.WorkoutDetails,
              CanDelete: false,
              Completed: !!WorkoutCompleted,
            },
          });

        return { Response };
      });
  };

  onPrintUnfreeze = () => {
    const { FreezeWorkoutForPrint } = this.props;
    const { WorkoutId } = this.props.match.params;
    FreezeWorkoutForPrint({ IsFrozen: 0, PDFLogo: null, WorkoutId });
    this.onToggleWorkoutFrozenStatus(false);
  };

  onSelectDates = (SelectedDates) => {
    if (
      this.state.WorkoutDetails.AssignedDate.substring(0, 10) !==
      SelectedDates[0].substring(0, 10)
    )
      this.setState(
        {
          WorkoutDetails: {
            ...this.state.WorkoutDetails,
            AssignedDate: moment(
              SelectedDates[0] +
                " " +
                this.state.WorkoutDetails.AssignedDate.substring(11, 16)
            ).format("YYYY-MM-DD HH:mm"),
          },
          ShowChooseDatesModal: false,
        },
        () => this.onUpdateWorkoutInformation()
      );
    else this.setState({ ShowChooseDatesModal: false });
  };

  onSelectNoteMedia = ({ event, UserType }) => {
    var FileSize = 0;
    var Files = event.target.files;
    var NumFiles = Files.length;

    var i;

    if (NumFiles > 0) {
      for (i = 0; i < NumFiles; i++) {
        FileSize += Math.round((Files[i].size / 1000) * 10) / 10;
      }

      var TotalUsedSize = FileSize + this.state[`${UserType}FileSize`];

      if (TotalUsedSize > this.state.MaxFileSizesKB)
        this.setState({ [`MediaTooLarge_${UserType}`]: TotalUsedSize });
      else {
        var SelectedMedia = [];

        for (i = 0; i < NumFiles; i++) {
          SelectedMedia.push({
            FileExtension: getFileExtension(Files[i].name),
            FileName: Files[i].name,
            FileSize_KB: Math.round((Files[i].size / 1000) * 10) / 10,
            IsNewMedia: true,
            MediaUrl: URL.createObjectURL(Files[i]),
          });
        }

        this.setState({
          [`MediaTooLarge_${UserType}`]: 0,
          [`${UserType}FileSize`]: TotalUsedSize,
          [`UploadMedia_${UserType}`]: [
            ...this.state[`UploadMedia_${UserType}`],
            ...Files,
          ],
          [`SelectedMedia_${UserType}`]: [
            ...this.state[`SelectedMedia_${UserType}`],
            ...SelectedMedia,
          ],
        });
      }

      document.getElementById("NoteMedia").value = null;
    }
  };

  onToggleEditingWorkoutName = () => {
    this.setState({ EditingWorkoutName: !this.state.EditingWorkoutName });
  };

  onToggleShowChooseDatesModal = (ShowChooseDatesModal) => {
    this.setState({ ShowChooseDatesModal });
  };

  onToggleShowCreateVideoSession = (ShowCreateVideoSession) => {
    this.setState({ ShowCreateVideoSession });
  };

  onToggleShowDeleteWorkoutModal = (ShowDeleteWorkoutModal) => {
    this.setState({ ShowDeleteWorkoutModal });
  };

  onToggleShowExportWorkoutModal = (ShowExportWorkoutModal) => {
    this.setState({ ShowExportWorkoutModal });
  };

  onToggleShowFullscreenMediaPreview = (
    PreviewMedia,
    PreviewMediaUrl,
    ShowFullscreenMediaPreview,
    PreviewMediaFileType
  ) => {
    if (this._isMounted)
      this.setState({
        PreviewMedia,
        PreviewMediaUrl,
        ShowFullscreenMediaPreview,
        PreviewMediaFileType,
      });
  };

  onToggleWorkoutFrozenStatus = (IsWorkoutFrozen) => {
    this.setState({ IsWorkoutFrozen });
  };

  onUpdateAssignedDate = (AssignedDate) => {
    this.setState({
      WorkoutDetails: { ...this.state.WorkoutDetails, AssignedDate },
    });
  };

  onUpdateWorkoutDetails = ({ CanDelete, WorkoutCompleted }) => {
    this.setState({
      WorkoutDetails: {
        ...this.state.WorkoutDetails,
        CanDelete,
        Completed: WorkoutCompleted,
      },
    });
  };

  onUpdateWorkoutImage = (WorkoutImage) => {
    // If Not CustomImage, Then Update To New Activity Image
    if (!this.props.WorkoutDetails.CustomImage)
      this.setState({
        WorkoutDetails: { ...this.state.WorkoutDetails, WorkoutImage },
      });
  };

  onUpdateWorkoutName = (WorkoutName) => {
    // Only update if WorkoutDetails.WorkoutName === Additional Activity Name
    // Otherwise, it was custom name and shouldn't change
    if (
      this.state.WorkoutDetails.ActivityName ===
      this.state.WorkoutDetails.WorkoutName
    ) {
      document.title = `ABEL - ${this.props.ClientDetails.FirstName && this.props.ClientDetails.LastName ? `${this.props.ClientDetails.FirstName} ${this.props.ClientDetails.LastName}` : this.props.ClientDetails.Email} - ${WorkoutName}`;
      this.setState({
        WorkoutDetails: {
          ...this.state.WorkoutDetails,
          ActivityName: WorkoutName,
          WorkoutName,
        },
      });
    }
  };

  onUpdateWorkoutInformation = () => {
    var {
      ClientDetails: { ClientId },
    } = this.props;
    var {
      OriginalWorkoutDetails,
      WorkoutDetails: { AssignedDate, Duration, WorkoutId, WorkoutName },
    } = this.state;

    AssignedDate = moment(AssignedDate).format("YYYY-MM-DD HH:mm");

    this.props
      .UpdateWorkoutInformation({
        AssignedDate,
        ClientId,
        Duration,
        WorkoutId,
        WorkoutName,
      })
      .then(() => {
        if (!this.props.TryingUpdateWorkoutInformationError)
          this.setState({
            OriginalWorkoutDetails: { ...this.state.WorkoutDetails },
          });
        else
          this.setState({
            WorkoutDetails: {
              ...this.state.WorkoutDetails,
              ...OriginalWorkoutDetails,
            },
          });
      });
  };

  onUploadNoteMedia = ({ NoteType }) => {
    var {
      ClientDetails: { ClientId },
    } = this.props;
    var {
      OriginalWorkoutDetails,
      WorkoutDetails: { ClientNote, TrainerNote, WorkoutId },
    } = this.state;

    var DeleteMedia = this.state[`DeleteMedia_${NoteType}`];
    var NoteMedia = this.state[`UploadMedia_${NoteType}`];
    var NoteText = NoteType === "Trainer" ? TrainerNote : ClientNote;

    var UploadMedia = NoteMedia.map(
      ({ name: FileName, size: FileSize, type: ContentType }) => ({
        ContentType,
        FileName,
        FileSize,
      })
    );

    this.props
      .InsertEditWorkoutNote({
        ClientId,
        DeleteMedia,
        NoteMedia: UploadMedia,
        NoteText,
        NoteType,
        WorkoutId,
      })
      .then(({ ClientDocuments, PreSignedUrls, TrainerDocuments }) => {
        if (this.props.TryingInsertEditWorkoutNoteError)
          this.setState({
            OriginalWorkoutDetails: { ...this.state.WorkoutDetails },
          });
        else {
          if (NoteMedia.length > 0 && PreSignedUrls.length > 0) {
            var UploadFunction = this.props.UploadToPresignedUrl;
            var Promises = PreSignedUrls.map((PreSignedUrl, index) =>
              CreateUploadToPresignedUrlPromise({
                ContentType: "WorkoutNotes",
                File: NoteMedia[index],
                PresignedFormData: PreSignedUrl,
                UploadFunction,
              })
            );

            // var NewDocs = this.state[`SelectedMedia_${NoteType}`].map(NewDoc => ({ ...NewDoc, IsNewMedia: false, NewlySaved: true }));

            Promise.allSettled(Promises)
              .then((data) => {
                var NewDeleteMedia = data
                  .map(({ status }, dataIndex) => {
                    if (status === "rejected") {
                      if (NoteType === "Trainer")
                        return {
                          Id: TrainerDocuments[
                            TrainerDocuments.length -
                              NoteMedia.length +
                              dataIndex
                          ].Id,
                          FileName:
                            TrainerDocuments[
                              TrainerDocuments.length -
                                NoteMedia.length +
                                dataIndex
                            ].FileName,
                        };
                      else if (NoteType === "Client")
                        return {
                          Id: ClientDocuments[
                            ClientDocuments.length -
                              NoteMedia.length +
                              dataIndex
                          ].Id,
                          FileName:
                            ClientDocuments[
                              ClientDocuments.length -
                                NoteMedia.length +
                                dataIndex
                            ].FileName,
                        };
                    }

                    return null;
                  })
                  .filter((media) => !!media);

                if (NewDeleteMedia.length) {
                  this.props
                    .InsertEditWorkoutNote({
                      ClientId,
                      DeleteMedia: NewDeleteMedia.map(({ Id }) => Id),
                      NoteMedia: [],
                      NoteText,
                      NoteType,
                      WorkoutId,
                    })
                    .then(({ ClientDocuments, TrainerDocuments }) => {
                      if (NoteMedia.length === 0 && DeleteMedia.length === 0) {
                        ClientDocuments =
                          this.state.WorkoutDetails.ClientDocuments;
                        TrainerDocuments =
                          this.state.WorkoutDetails.TrainerDocuments;
                      }

                      if (NoteType === "Client")
                        this.setState({
                          DeleteMedia_Client: [],
                          SelectedMedia_Client: [],
                          UploadingErrors: {
                            NoteType,
                            Files: NewDeleteMedia.map(
                              ({ FileName }) => FileName
                            ),
                          },
                          UploadMedia_Client: [],
                          WorkoutDetails: {
                            ...OriginalWorkoutDetails,
                            ...this.state.WorkoutDetails,
                            ClientDocuments: [...ClientDocuments],
                          },
                        });
                      else if (NoteType === "Trainer")
                        this.setState({
                          DeleteMedia_Trainer: [],
                          SelectedMedia_Trainer: [],
                          UploadingErrors: {
                            NoteType,
                            Files: NewDeleteMedia.map(
                              ({ FileName }) => FileName
                            ),
                          },
                          UploadMedia_Trainer: [],
                          WorkoutDetails: {
                            ...OriginalWorkoutDetails,
                            ...this.state.WorkoutDetails,
                            TrainerDocuments: [...TrainerDocuments],
                          },
                        });
                    });
                } else {
                  if (NoteMedia.length === 0 && DeleteMedia.length === 0) {
                    ClientDocuments = this.state.WorkoutDetails.ClientDocuments;
                    TrainerDocuments =
                      this.state.WorkoutDetails.TrainerDocuments;
                  }

                  if (NoteType === "Client")
                    this.setState({
                      DeleteMedia_Client: [],
                      SelectedMedia_Client: [],
                      UploadingErrors: { NoteType: null, Files: [] },
                      UploadMedia_Client: [],
                      WorkoutDetails: {
                        ...OriginalWorkoutDetails,
                        ...this.state.WorkoutDetails,
                        ClientDocuments: [...ClientDocuments],
                      },
                    });
                  else if (NoteType === "Trainer")
                    this.setState({
                      DeleteMedia_Trainer: [],
                      SelectedMedia_Trainer: [],
                      UploadingErrors: { NoteType: null, Files: [] },
                      UploadMedia_Trainer: [],
                      WorkoutDetails: {
                        ...OriginalWorkoutDetails,
                        ...this.state.WorkoutDetails,
                        TrainerDocuments: [...TrainerDocuments],
                      },
                    });
                }
              })
              .catch((error) => console.log("error: ", error));
          } else {
            if (NoteMedia.length === 0 && DeleteMedia.length === 0) {
              ClientDocuments = this.state.WorkoutDetails.ClientDocuments;
              TrainerDocuments = this.state.WorkoutDetails.TrainerDocuments;
            }

            if (NoteType === "Client")
              this.setState({
                DeleteMedia_Client: [],
                SelectedMedia_Client: [],
                UploadingErrors: { NoteType: null, Files: [] },
                UploadMedia_Client: [],
                WorkoutDetails: {
                  ...OriginalWorkoutDetails,
                  ...this.state.WorkoutDetails,
                  ClientDocuments,
                },
              });
            else if (NoteType === "Trainer")
              this.setState({
                DeleteMedia_Trainer: [],
                SelectedMedia_Trainer: [],
                UploadingErrors: { NoteType: null, Files: [] },
                UploadMedia_Trainer: [],
                WorkoutDetails: {
                  ...OriginalWorkoutDetails,
                  ...this.state.WorkoutDetails,
                  TrainerDocuments,
                },
              });
          }
        }
      });
  };

  renderMediaPreview = ({
    Document: {
      Id: DocumentId,
      FileExtension,
      FileName,
      FileSize_KB,
      IsNewMedia,
      MediaUrl,
      NewlySaved,
    },
    Editable,
    UserType,
  }) => {
    FileExtension = FileExtension || getFileExtension(MediaUrl);
    IsNewMedia = IsNewMedia || false;

    var DeleteMediaIndex =
      this.state[`DeleteMedia_${UserType}`].indexOf(DocumentId);
    var IsDeletedSelected = DeleteMediaIndex !== -1;

    switch (FileExtension) {
      case "png":
      case "jpg":
      case "jpeg":
        if (NewlySaved) {
          return (
            <MediaPreview key={MediaUrl}>
              <StyledMediaPreview
                ImageSrc={MediaUrl}
                IsDeletedSelected={IsDeletedSelected}
                NewlySaved={NewlySaved}
                OnClick={() =>
                  this.onToggleShowFullscreenMediaPreview(null, MediaUrl, true)
                }
                SelfLoading
              />
            </MediaPreview>
          );
        }

        return (
          <MediaPreview key={MediaUrl}>
            <StyledMediaPreview
              ImageSrc={MediaUrl}
              IsDeletedSelected={IsDeletedSelected}
              IsNewMedia={IsNewMedia}
              OnClick={() =>
                this.onToggleShowFullscreenMediaPreview(null, MediaUrl, true)
              }
              SelfLoading
            />
            {Editable && (
              <MultiSelect
                onClick={() =>
                  this.onDeleteMedia({
                    DeleteMediaIndex,
                    DocumentId,
                    FileSize_KB,
                    IsNewMedia,
                    MediaUrl,
                    UserType,
                  })
                }
                Selected={IsDeletedSelected}
              />
            )}
          </MediaPreview>
        );

      case "mov":
      case "mp4":
        if (NewlySaved) {
          return (
            <MediaPreview key={MediaUrl}>
              <StyledVideoPreview
                IsDeletedSelected={IsDeletedSelected}
                NewlySaved={NewlySaved}
                autoPlay={false}
                controls={false}
                height="100%"
                width="100%"
                onClick={() =>
                  this.onToggleShowFullscreenMediaPreview(
                    null,
                    MediaUrl,
                    true,
                    "video"
                  )
                }
                src={MediaUrl}
              />
            </MediaPreview>
          );
        }

        return (
          <MediaPreview key={MediaUrl}>
            <StyledVideoPreview
              IsDeletedSelected={IsDeletedSelected}
              IsNewMedia={IsNewMedia}
              autoPlay={false}
              controls={false}
              height="100%"
              width="100%"
              onClick={() =>
                this.onToggleShowFullscreenMediaPreview(
                  null,
                  MediaUrl,
                  true,
                  "video"
                )
              }
              src={MediaUrl}
            />
            {Editable && (
              <MultiSelect
                onClick={() =>
                  this.onDeleteMedia({
                    DeleteMediaIndex,
                    DocumentId,
                    FileSize_KB,
                    IsNewMedia,
                    MediaUrl,
                    UserType,
                  })
                }
                Selected={IsDeletedSelected}
              />
            )}
          </MediaPreview>
        );

      case "pdf":
        if (NewlySaved)
          return (
            <StyledMediaDocumentPreview
              key={MediaUrl}
              IsDeletedSelected={IsDeletedSelected}
              NewlySaved={NewlySaved}
            >
              <ButtonIcon src={PDF} alt="Button icon" />
              <PageText
                ElementType="span"
                FontFamily="medium"
                FontSize="medium-1"
                JustifyContent="flex-start"
                NoMargin
                Text={FileName}
                TextAlign="left"
              />
            </StyledMediaDocumentPreview>
          );
        if (IsNewMedia)
          return (
            <StyledMediaDocumentPreview
              key={MediaUrl}
              IsDeletedSelected={IsDeletedSelected}
              IsNewMedia={IsNewMedia}
            >
              <ButtonIcon src={PDF} alt="Button icon" />
              <PageText
                ElementType="span"
                FontFamily="medium"
                FontSize="medium-1"
                JustifyContent="flex-start"
                NoMargin
                Text={FileName}
                TextAlign="left"
              />
              <MultiSelect
                onClick={(event) =>
                  this.onDeleteMedia({
                    event,
                    DeleteMediaIndex,
                    DocumentId,
                    FileSize_KB,
                    IsNewMedia,
                    MediaUrl,
                    UserType,
                  })
                }
                Selected={IsDeletedSelected}
              />
            </StyledMediaDocumentPreview>
          );
        return (
          <StyledExternalLink key={MediaUrl} href={MediaUrl} target="_new">
            <StyledMediaDocumentPreview
              IsDeletedSelected={IsDeletedSelected}
              IsNewMedia={IsNewMedia}
            >
              <ButtonIcon src={PDF} alt="Button icon" />
              <PageText
                ElementType="span"
                FontFamily="medium"
                FontSize="medium-1"
                JustifyContent="flex-start"
                NoMargin
                Text={FileName}
                TextAlign="left"
              />
              {Editable && (
                <MultiSelect
                  onClick={(event) =>
                    this.onDeleteMedia({
                      event,
                      DeleteMediaIndex,
                      DocumentId,
                      FileSize_KB,
                      IsNewMedia,
                      MediaUrl,
                      UserType,
                    })
                  }
                  Selected={IsDeletedSelected}
                />
              )}
            </StyledMediaDocumentPreview>
          </StyledExternalLink>
        );

      default:
        return (
          <StyledMediaDocumentPreview
            key={MediaUrl}
            IsDeletedSelected={IsDeletedSelected}
            IsNewMedia={IsNewMedia}
          >
            <PageText
              ElementType="span"
              FontFamily="medium"
              FontSize="medium-1"
              JustifyContent="flex-start"
              NoMargin
              Text={FileName}
              TextAlign="left"
            />
            <MultiSelect
              onClick={(event) =>
                this.onDeleteMedia({
                  event,
                  DeleteMediaIndex,
                  DocumentId,
                  FileSize_KB,
                  IsNewMedia,
                  MediaUrl,
                  UserType,
                })
              }
              Selected={IsDeletedSelected}
            />
          </StyledMediaDocumentPreview>
        );
      // return <div key={MediaUrl}>{FileName}</div>
    }
  };

  renderPreviewFullscreenMedia = () => {
    var {
      PreviewMedia,
      PreviewMediaFileType,
      PreviewMediaUrl,
      ShowFullscreenMediaPreview,
    } = this.state;

    if (PreviewMediaUrl && ShowFullscreenMediaPreview) {
      return (
        <PreviewMediaModal
          FileType={PreviewMediaFileType}
          Media={PreviewMedia}
          MediaUrl={PreviewMediaUrl}
          OnHide={() =>
            this.onToggleShowFullscreenMediaPreview(null, null, false)
          }
          Show={ShowFullscreenMediaPreview}
        />
      );
    }
  };

  renderShowChooseDatesModal = () => {
    var { ShowChooseDatesModal } = this.state;

    if (ShowChooseDatesModal) {
      var AssignedDate = moment(this.state.WorkoutDetails.AssignedDate).format(
        "YYYY-MM-DD"
      );

      return (
        <ChooseDates
          MaxDates={1}
          OnHide={() => this.onToggleShowChooseDatesModal(false)}
          OnSelectDates={(SelectedDates) => this.onSelectDates(SelectedDates)}
          RequiredDate
          SelectedDates={[AssignedDate]}
          Show={ShowChooseDatesModal}
        />
      );
    }
  };

  renderShowDeleteWorkoutModal = () => {
    var { ShowDeleteWorkoutModal } = this.state;

    if (ShowDeleteWorkoutModal) {
      var { t } = this.props;

      return (
        <Info
          BottomButton="submit"
          BottomButtonOnClick={this.onDeleteWorkout}
          BottomButtonText={t("delete_with_first_char_uppercase")}
          Information={t(
            "WorkoutPlan_are_you_sure_you_want_to_remove_this_workout"
          )}
          OnHide={() => this.onToggleShowDeleteWorkoutModal(false)}
          Show={ShowDeleteWorkoutModal}
          Size="small"
          TextAlign="center"
        />
      );
    }
  };

  renderShowExportWorkoutPdfModal = () => {
    const { ShowExportWorkoutModal } = this.state;
    const { WorkoutId } = this.props.match.params;

    if (ShowExportWorkoutModal) {
      return (
        <ExportWorkoutPdf
          WorkoutId={WorkoutId}
          onToggleWorkoutFrozenStatus={this.onToggleWorkoutFrozenStatus}
          OnHide={() => this.onToggleShowExportWorkoutModal(false)}
          Show={ShowExportWorkoutModal}
          Size="large"
          TextAlign="center"
        />
      );
    }
  };

  renderUploadNoteMedia = ({ Documents, UserType }) => {
    var { t } = this.props;
    var {
      Device,
      ClientDetails: { ClientId },
      UserId,
    } = this.props;
    var {
      DeleteMedia_Client,
      DeleteMedia_Trainer,
      SelectedMedia_Client,
      SelectedMedia_Trainer,
      OriginalWorkoutDetails: { ClientNote: OCN, TrainerNote: OTN },
      UploadingErrors,
      WorkoutDetails: { ClientNote: CN, TrainerNote: TN },
    } = this.state;

    if (UserType === "Client")
      Documents = [...Documents, ...SelectedMedia_Client];
    else if (UserType === "Trainer")
      Documents = [...Documents, ...SelectedMedia_Trainer];

    var Disabled = false;
    if (
      UserType === "Client" &&
      SelectedMedia_Client.length === 0 &&
      DeleteMedia_Client.length === 0 &&
      OCN === CN
    )
      Disabled = true;
    else if (
      UserType === "Trainer" &&
      SelectedMedia_Trainer.length === 0 &&
      DeleteMedia_Trainer.length === 0 &&
      OTN === TN
    )
      Disabled = true;

    var Buttons = [
      {
        BackgroundColor: "white-concrete",
        BackgroundColorHover: "white-concrete-hover",
        Border: "none",
        Color: "black",
        ColorHover: "black",
        FontFamily: "semibold",
        Icon: Camera,
        IconPosition: "left",
        IsInput: true,
        InputProps: {
          accept:
            "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg,video/mov,video/mp4,.mov,.mp4,application/pdf,.pdf",
          id: "NoteMedia",
          multiple: "multiple",
          type: "file",
          onChange: (event) => this.onSelectNoteMedia({ event, UserType }),
        },
        Title: t("Upload"),
      },
      {
        BackgroundColor: Disabled ? "gray" : "blue-astronaut",
        BackgroundColorHover: Disabled ? "gray-hover" : "blue-astronaut-hover",
        Border: "none",
        Color: Disabled ? "black" : "white",
        ColorHover: Disabled ? "black" : "white",
        FontFamily: "semibold",
        OnClick: Disabled
          ? null
          : () => this.onUploadNoteMedia({ NoteType: UserType }),
        Title: t("save_with_first_char_uppercase"),
      },
    ];

    var MaxFileSizesKB = this.state.MaxFileSizesKB / 1024;

    var UploadingErrorFileNames =
      UploadingErrors.NoteType === UserType
        ? UploadingErrors.Files.join(", ")
        : null;

    return (
      <UploadContainer>
        <UploadButtonsRow>
          <UploadButtonsContainer
            Buttons={Buttons}
            ButtonWidth="fit-content"
            ContainerWidth={
              Device === "mobile_small" || Device === "mobile" ? "100%" : null
            }
            NotTouching
          />
        </UploadButtonsRow>

        {(UserType === "Trainer" ||
          (UserType === "Client" && +ClientId === +UserId)) && (
          <PageText
            FontFamily="medium"
            FontSize="medium-1"
            Text={`${
              Math.round((this.state[`${UserType}FileSize`] / 1024) * 100) / 100
            }MB / ${MaxFileSizesKB}MB ${t("used")}`}
          />
        )}

        {!!+this.state[`MediaTooLarge_${UserType}`] && (
          <PageText
            ContainerWidth="100%"
            FontColor="red-bittersweet"
            FontFamily="medium-italic"
            FontSize="medium-1"
            JustifyContent="center"
            Text={t("file_too_large", {
              MaxUploadSize: `${MaxFileSizesKB}MB`,
              UploadedSize: `${
                Math.round(
                  (this.state[`MediaTooLarge_${UserType}`] / 1024) * 100
                ) / 100
              }MB`,
            })}
            TextAlign="center"
          />
        )}

        {!!UploadingErrorFileNames && (
          <PageText
            ContainerWidth="100%"
            FontColor="red-bittersweet"
            FontFamily="medium-italic"
            FontSize="medium-1"
            JustifyContent="center"
            Text={t("uploading_files_error", {
              FileNames: UploadingErrorFileNames,
            })}
            TextAlign="center"
          />
        )}

        {Documents.length > 0 && (
          <UploadDocumentsContainer>
            {Documents.map((Document) =>
              this.renderMediaPreview({ Document, Editable: true, UserType })
            )}
          </UploadDocumentsContainer>
        )}
      </UploadContainer>
    );
  };

  renderWearableData = () =>  {
    var { t } = this.props;
    var { WorkoutDetails: { WearableData } } = this.state;
    var { graphLabels: { xLabel, yLabel }, heartRates, heartRateZones, Summary } = WearableData;

    return (
      <styles.WearableDataContainer className="WearableDataContainer">
        <ChartContainer className="ChartContainer">
          <styles.ChartContainerHeader className="ChartContainerHeader">
            <styles.ChartContainerHeaderLeft className="ChartContainerHeaderLeft">
              <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('heart_rate_chart')} TextAlign="left" />
            </styles.ChartContainerHeaderLeft>
            
            <styles.ChartContainerHeaderRight className="ChartContainerHeaderRight">
              <MultiText
                Texts={[
                  { FontFamily: "medium", FontSize: "medium-1", Text: t('avg') },
                  { FontColorHex: '#FD9326', FontFamily: "bold", FontSize: "medium-1", Text: `${Summary.AverageHR}` },
                ]}
              />
              <MultiText
                Texts={[
                  { FontFamily: "medium", FontSize: "medium-1", Text: t('max') },
                  { FontFamily: "bold", FontSize: "medium-1", Text: `${Summary.MaxHR}` },
                ]}
              />
            </styles.ChartContainerHeaderRight>
          </styles.ChartContainerHeader>

          <Spacer Size="small" />

          <HeartRateLineGraph
            Measurements={heartRates}
            Summary={Summary}
            xLabels={xLabel}
            yLabels={yLabel}
          />
        </ChartContainer>

        <styles.HeartRateZonesContainer className="HeartRateZonesContainer">
          <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('heart_rate_zones')} TextAlign="left" />

          <Spacer Size="small" />

          <styles.HeartRateZoneChartContainer className="HeartRateZoneChartContainer">
              <HeartRateZoneChart
                t={t}
                Zones={heartRateZones}
              />
          </styles.HeartRateZoneChartContainer>
        </styles.HeartRateZonesContainer>
      </styles.WearableDataContainer>
    );
  }

  renderWorkoutCreateVideoSession() {
    const { t } = this.props;
    const { ShowCreateVideoSession } = this.state;
    const { WorkoutId } = this.props.match.params;
    return (
      <>
        {!ShowCreateVideoSession ? (
          <WorkoutVideoSessionContainer className="WorkoutVideoSessionContainer">
            <StyledVideoScreenIcon
              ImageSrc={Screen}
              SelfLoading
              OnClick={() => this.onToggleShowCreateVideoSession(true)}
            />
            <div>
              <PageText
                FontFamily="semibold"
                FontSize="medium-1"
                JustifyContent="flex-start"
                Text={"Add a video call to this session"}
                TextAlign="left"
              />
              <PageText
                FontFamily="medium"
                FontSize="medium-small"
                JustifyContent="flex-start"
                Text={t("workout_video_call_session_subtitle")}
                TextAlign="left"
              />
            </div>
          </WorkoutVideoSessionContainer>
        ) : (
          <CreateVideoSession
            WorkoutId={Number(WorkoutId)}
            hideCreateSession={this.onToggleShowCreateVideoSession.bind(this)}
          />
        )}
      </>
    );
  }

  renderWorkoutDateAndName = () => {
    var { t } = this.props;
    var { Device } = this.props;
    var {
      EditingWorkoutName,
      WorkoutDetails: { AssignedDate, WorkoutName, WorkoutType },
    } = this.state;

    var Buttons = [
      {
        BackgroundColor: "white-concrete",
        BackgroundColorHover: "white-concrete-hover",
        Border: "none",
        Color: "black",
        ColorHover: "black",
        FontFamily: "semibold",
        Icon: DownloadGray,
        IconPosition: "left",
        OnClick: () => this.onToggleShowExportWorkoutModal(true),
        Title: "PDF",
      },
    ];

    return (
      <styles.WorkoutDateAndNameContainer className="WorkoutDateAndNameContainer">
        <styles.WorkoutNameContainer className="WorkoutNameContainer">
          {EditingWorkoutName ? (
            <StyledWorkoutNameInput
              FontFamily="medium"
              FontSize="large"
              NoLabel
              OnBlur={this.onBlurWorkoutName}
              OnChange={this.onChangeWorkoutName}
              Placeholder={t("ptadmin_clients_add_singular_placeholder")}
              Size="medium"
              Type="text"
              Value={WorkoutName}
            />
          ) : (
            <PageText
              FontFamily="medium"
              FontSize="large"
              JustifyContent={
                Device === "laptop" || Device === "ipad"
                  ? "flex-start"
                  : "center"
              }
              NoMargin
              Text={WorkoutName}
              TextAlign={
                Device === "laptop" || Device === "ipad" ? "left" : "center"
              }
            />
          )}

          <styles.EditButton onClick={this.onToggleEditingWorkoutName}>
            <StyledEditWorkoutNameIcon ImageSrc={PencilGray} />
          </styles.EditButton>
        </styles.WorkoutNameContainer>

        <styles.WorkoutDateContainer className="WorkoutDateContainer">
          {(WorkoutType === 1 || WorkoutType === 2) && (
            <>
              <UploadButtonsContainer
                Buttons={Buttons}
                ButtonWidth="fit-content"
                ContainerWidth={
                  Device === "mobile_small" || Device === "mobile"
                    ? "100%"
                    : null
                }
                NotTouching
              />
            </>
          )}

          <MultiText
            TextAlign={
              Device === "laptop" || Device === "ipad" ? "right" : "center"
            }
            Texts={[
              {
                FontFamily: "medium",
                FontSize: "medium-1",
                Text: `${t("Date_str")} - `,
              },
              {
                FontFamily: "semibold",
                FontSize: "medium-1",
                Text: moment(AssignedDate).format("MMM D"),
              },
            ]}
          />

          <styles.EditButton
            onClick={() => this.onToggleShowChooseDatesModal(true)}
          >
            <StyledEditWorkoutNameIcon ImageSrc={PencilGray} />
          </styles.EditButton>
        </styles.WorkoutDateContainer>
      </styles.WorkoutDateAndNameContainer>
    );
  };

  renderWorkoutDuration = () => {
    var { t } = this.props;
    var { Duration } = this.state.WorkoutDetails;

    return (
      <DurationContainer className="DurationContainer">
        <DurationInnerContainer className="DurationInnerContainer">
          <PageText
            FontFamily="medium"
            FontSize="medium-2"
            Text={t("WorkoutPlan_duration_on_minutes")}
            TextAlign="center"
          />

          <Spacer Size="extra-exta-small" />

          <StyledDuration
            NoDecimal
            NoLabel
            NoMargin
            OnBlur={this.onBlurDuration}
            OnChange={this.onChangeDuration}
            Placeholder="0"
            Size="medium"
            Type="number"
            Value={Duration}
          />
        </DurationInnerContainer>
      </DurationContainer>
    );
  };

  renderWorkoutExistingVideoSession() {
    const { SingleSessionData } = this.props;
    const { WorkoutId } = this.props.match.params;
    return this.state.isEditSession ? (
      <CreateVideoSession
        key={SingleSessionData.VideoSessionId}
        WorkoutId={Number(WorkoutId)}
        SelectedSession={SingleSessionData}
        onCancelSession={this.onToggleEditSession.bind(this)}
      />
    ) : (
      <GetVideoSessions
        key={SingleSessionData.VideoSessionId}
        session={SingleSessionData}
        isSessionDeleted={this.isSessionDeleted.bind(this)}
        onEditSession={this.onToggleEditSession.bind(this)}
      />
    );
  }

  renderWorkoutFrozenStatus = () => {
    const { t } = this.props;
    return (
      <WorkoutFrozenStatusContainer className="WorkoutFrozenStatusContainer">
        <WorkoutFrozenContainer className="WorkoutFrozenContainer">
          <WorkoutFrozen className="WorkoutFrozen">
            <StyledFrozenWorkoutIcon
              ImageSrc={FreezeWorkoutSnowImage}
              SelfLoading
            />
            <PageText
              FontFamily="semibold"
              FontSize="medium-1"
              JustifyContent="flex-start"
              NoMargin
              FontColor="blue-frozen"
              Text={t("frozen_workout")}
              TextAlign="left"
            />
          </WorkoutFrozen>
          <UnfreezeTextContainer
            className="UnfreezeTextContainer"
            onClick={this.onPrintUnfreeze}
          >
            <PageText
              FontColor="red-bittersweet"
              FontFamily="semibold"
              FontSize="medium-1"
              JustifyContent="flex-start"
              NoMargin
              Text={t("unfreeze")}
              TextAlign="left"
            />
          </UnfreezeTextContainer>
        </WorkoutFrozenContainer>

        <Spacer Size="extra-small" />

        <PageText
          FontFamily="medium"
          FontSize="medium-1"
          JustifyContent="flex-start"
          NoMargin
          Text={t("frozen_workout_description")}
          TextAlign="left"
        />
      </WorkoutFrozenStatusContainer>
    );
  };

  renderWorkoutImage = () => {
    return (
      <StyledWorkoutImage
        ImageSrc={this.state.WorkoutDetails.WorkoutImage}
        SelfLoading
      />
    );
  };

  renderWorkoutNotes = () => {
    var { t } = this.props;
    var {
      ClientDetails: { ClientId },
      UploadProgress,
      UserId,
    } = this.props;
    var {
      WorkoutDetails: {
        ClientDocuments,
        ClientNote,
        TrainerDocuments,
        TrainerNote,
      },
    } = this.state;

    return (
      <>
        <WorkoutNotesContainer className="WorkoutNotesContainer">
          <WorkoutNoteContainer className="WorkoutNoteContainer">
            <WorkoutNotesContainerInner className="WorkoutNotesContainerInner">
              <PageText
                FontFamily="semibold"
                FontSize="medium-1"
                JustifyContent="flex-start"
                NoMargin
                Text={t("WorkoutPlan_Trainer_note_to_client")}
                TextAlign="left"
              />

              <Spacer Size="small" />

              <StyledWorkoutNoteTextArea
                OnBlur={(event) =>
                  this.onChangeWorkoutNote(event, "TrainerNote")
                }
                NumRows={4}
                Placeholder={t(
                  "WorkoutPlan_Trainer_note_to_client_placeholder"
                )}
                Value={TrainerNote}
              />
            </WorkoutNotesContainerInner>
            {this.renderUploadNoteMedia({
              Documents: TrainerDocuments,
              UserType: "Trainer",
            })}
          </WorkoutNoteContainer>

          <WorkoutNoteContainer className="WorkoutNoteContainer">
            <WorkoutNotesContainerInner className="WorkoutNotesContainerInner">
              <PageText
                FontFamily="semibold"
                FontSize="medium-1"
                JustifyContent="flex-start"
                NoMargin
                Text={t("WorkoutPlan_Trainer_note_from_client")}
                TextAlign="left"
              />

              <Spacer Size="small" />

              {+ClientId === +UserId ? (
                <StyledWorkoutNoteTextArea
                  OnBlur={(event) =>
                    this.onChangeWorkoutNote(event, "ClientNote")
                  }
                  NumRows={4}
                  Placeholder={t(
                    "WorkoutPlan_Trainer_note_from_client_placeholder"
                  )}
                  Value={ClientNote}
                />
              ) : (
                <>
                  <PageText
                    FontFamily="medium"
                    FontSize="medium-1"
                    JustifyContent="flex-start"
                    NoMargin
                    Text={ClientNote}
                    TextAlign="left"
                    WhiteSpace="pre-line"
                  />

                  {ClientDocuments.length > 0 && (
                    <>
                      <Spacer Size="small" />

                      <UploadDocumentsContainer className="UploadDocumentsContainer">
                        {ClientDocuments.map((Document) => {
                          return (
                            <React.Fragment key={Document.Id}>
                              {this.renderMediaPreview({
                                Document,
                                Editable: false,
                                UserType: "Client",
                              })}
                            </React.Fragment>
                          );
                        })}
                      </UploadDocumentsContainer>
                    </>
                  )}
                </>
              )}
            </WorkoutNotesContainerInner>
            {+ClientId === +UserId &&
              this.renderUploadNoteMedia({
                Documents: ClientDocuments,
                UserType: "Client",
              })}
          </WorkoutNoteContainer>
        </WorkoutNotesContainer>

        {UploadProgress.WorkoutNotes &&
          Object.entries(UploadProgress.WorkoutNotes).length > 0 && (
            <>
              <Spacer Size="medium" />

              <UploadProgressComponent
                UploadingItems={UploadProgress.WorkoutNotes}
              />
            </>
          )}
      </>
    );
  };

  render() {
    if (this.state.Loading || this.props.TryingGetWorkoutDetail)
      return <Loading />;

    var {
      AdditionalActivityDetails,
      ClientDetails,
      TryingCompleteWorkout,
      TryingDeleteWorkout,
      TryingInsertEditWorkoutNote,
      TryingUpdateWorkoutInformation,
      WorkoutDetails: { ActivityId, ActivityName, IntensityId, SurveyData = [] },
      WorkoutExercises,
      WorkoutLaps,
    } = this.props;
    var { WorkoutDetails, IsWorkoutFrozen } = this.state;

    var Breadcrumbs = [
      {
        Name: `${ClientDetails.FirstName && ClientDetails.LastName ? `${ClientDetails.FirstName} ${ClientDetails.LastName}` : ClientDetails.Email}`,
        LinkURL: `client/${ClientDetails.ClientId}`,
        Props: {
          SelectedTab: "Workouts",
          WorkoutDate: WorkoutDetails.AssignedDate,
        },
        Search: "?tab=workouts",
      },
      { Name: WorkoutDetails.WorkoutName },
    ];

    // Skipping workout video session feature for current release */
    // const { SingleSessionData } = this.props;
    // const SessionPresent =
    //   SingleSessionData && !!SingleSessionData.VideoSessionId;

    return (
      <>
        {(TryingInsertEditWorkoutNote || TryingUpdateWorkoutInformation) && (
          <Loading />
        )}

        <Page renderBreadcrumbs={Breadcrumbs}>
          {this.renderWorkoutDateAndName()}
          <Spacer Size="small" />
          {IsWorkoutFrozen && (
            <>
              {this.renderWorkoutFrozenStatus()}

              <Spacer Size="small" />
            </>
          )}
          {
            <>
              {this.renderWorkoutImage()}

              <Spacer Size="small" />
            </>
          }
          {this.renderWorkoutNotes()}

          <Spacer Size="medium" />
          <styles.StyledHR />
          <Spacer Size="medium" />

          {/* Skipping workout video session feature for current release */}
          {/* {SessionPresent
            ? this.renderWorkoutExistingVideoSession()
            : this.renderWorkoutCreateVideoSession()}
          <Spacer Size="medium" /> */}

          {/* <styles.StyledHR />
          <Spacer Size="medium" /> */}
          <AdditionalActivity
            AdditionalActivityDetails={{
              ...AdditionalActivityDetails,
              ActivityId,
              ActivityName,
              AssignedDate: WorkoutDetails.AssignedDate,
              Duration: WorkoutDetails.Duration,
              IntensityId,
              ShowWorkoutTime: WorkoutDetails.ShowWorkoutTime,
              WorkoutLocation: WorkoutDetails.WorkoutLocation,
            }}
            CanDelete={!!WorkoutDetails.CanDelete}
            ClientId={ClientDetails.ClientId}
            Completed={!!WorkoutDetails.Completed}
            ExistingWorkoutId={+this.props.match.params.WorkoutId}
            SurveyData={SurveyData}
            OnDeleteWorkout={() => this.onToggleShowDeleteWorkoutModal(true)}
            OnUpdateAssignedDate={(AssignedDate) =>
              this.onUpdateAssignedDate(AssignedDate)
            }
            OnUpdateWorkoutDetails={({ CanDelete, WorkoutCompleted }) =>
              this.onUpdateWorkoutDetails({ CanDelete, WorkoutCompleted })
            }
            OnUpdateWorkoutImage={(WorkoutImage) =>
              this.onUpdateWorkoutImage(WorkoutImage)
            }
            OnUpdateWorkoutName={(WorkoutName) =>
              this.onUpdateWorkoutName(WorkoutName)
            }
            TryingDeleteWorkout={TryingDeleteWorkout}
            WorkoutType={WorkoutDetails.WorkoutType}
          />
          {WorkoutDetails.WorkoutType !== 3 && (
            <>
              <Spacer Size="small" />

              {this.renderWorkoutDuration()}

              <Spacer Size="medium" />

              <styles.StyledHR />

              <Spacer Size="medium" />
            </>
          )}

          {(!!+WorkoutDetails.IsGarmin || !!+WorkoutDetails.IsGarminMerged) && (
            <>
              {WorkoutDetails.WorkoutType === 3 && <Spacer Size="medium" />}

              {this.renderWearableData()}

              <Spacer Size="medium" />

              <styles.StyledHR />

              <Spacer Size="medium" />
            </>
          )}

          {WorkoutDetails.WorkoutType === 1 ? (
            <StrengthWorkout
              CanDelete={!!WorkoutDetails.CanDelete}
              ClientId={ClientDetails.ClientId}
              Completed={!!WorkoutDetails.Completed}
              ExistingWorkoutId={+this.props.match.params.WorkoutId}
              OnCopyWorkoutToTemplate={() => this.onCopyWorkoutToTemplate()}
              OnDeleteWorkout={() => this.onToggleShowDeleteWorkoutModal(true)}
              OnMarkCompleted={this.onMarkCompleted}
              OnUpdateWorkoutDetails={({ CanDelete, WorkoutCompleted }) =>
                this.onUpdateWorkoutDetails({ CanDelete, WorkoutCompleted })
              }
              TryingCompleteWorkout={TryingCompleteWorkout}
              TryingDeleteWorkout={TryingDeleteWorkout}
              WorkoutDetails={WorkoutDetails}
              WorkoutExercises={WorkoutExercises}
            />
          ) : WorkoutDetails.WorkoutType === 2 ? (
            <EnduranceWorkout
              CanDelete={!!WorkoutDetails.CanDelete}
              ClientId={ClientDetails.ClientId}
              Completed={!!WorkoutDetails.Completed}
              ExistingWorkoutId={+this.props.match.params.WorkoutId}
              OnCopyWorkoutToTemplate={(WorkoutLaps) =>
                this.onCopyWorkoutToTemplate(WorkoutLaps)
              }
              OnDeleteWorkout={() => this.onToggleShowDeleteWorkoutModal(true)}
              OnMarkCompleted={this.onMarkCompleted}
              OnUpdateWorkoutDetails={({ CanDelete, WorkoutCompleted }) =>
                this.onUpdateWorkoutDetails({ CanDelete, WorkoutCompleted })
              }
              TryingCompleteWorkout={TryingCompleteWorkout}
              TryingDeleteWorkout={TryingDeleteWorkout}
              WorkoutDetails={WorkoutDetails}
              WorkoutLaps={WorkoutLaps}
            />
          ) : null}
        </Page>

        {this.renderPreviewFullscreenMedia()}
        {this.renderShowChooseDatesModal()}
        {this.renderShowDeleteWorkoutModal()}
        {this.renderShowExportWorkoutPdfModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Device: state.Window.Device,
    UserId: state.Auth.UserDetails.UserId,

    ClientDetails: state.Client.ClientDetails,

    AdditionalActivityDetails: state.Workout.AdditionalActivityDetails,
    WorkoutDetails: state.Workout.WorkoutDetails,
    WorkoutExercises: state.Workout.WorkoutExercises,
    WorkoutLaps: state.Workout.WorkoutLaps,
    VideoSessionInfo: state.Workout.VideoSessionInfo,

    SingleSessionData: state.VideoSession.SingleSessionData,

    UploadProgress: state.UploadContent.UploadProgress,

    TryingCompleteWorkout: state.Workout.TryingCompleteWorkout,
    TryingCompleteWorkoutError: state.Workout.TryingCompleteWorkoutError,
    TryingDeleteWorkout: state.Workout.TryingDeleteWorkout,
    TryingDeleteWorkoutError: state.Workout.TryingDeleteWorkoutError,
    TryingGetWorkoutDetail: state.Workout.TryingGetWorkoutDetail,
    TryingGetWorkoutDetailError: state.Workout.TryingGetWorkoutDetailError,
    TryingInsertEditWorkoutNote: state.Workout.TryingInsertEditWorkoutNote,
    TryingInsertEditWorkoutNoteError:
      state.Workout.TryingInsertEditWorkoutNoteError,
    TryingUpdateWorkoutInformation:
      state.Workout.TryingUpdateWorkoutInformation,
    TryingUpdateWorkoutInformationError:
      state.Workout.TryingUpdateWorkoutInformationError,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    AutoCompleteWorkout,
    ClearClientDetails,
    ClearSingleVideoSession,
    ClearWorkoutDetail,
    DeleteWorkout,
    FreezeWorkoutForPrint,
    GetSingleVideoSessionDetails,
    GetWorkoutNotes,
    GetWorkoutDetail,
    InsertEditWorkoutNote,
    SaveDataInProps,
    UpdateSelectedTab,
    UpdateWorkoutInformation,
    UploadToPresignedUrl,
  })(WorkoutDetail)
);

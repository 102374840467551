import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  CopyMeals,
  DeleteMeals,
  GetClientMeals,
  MoveMeals,
  SaveDataInProps,
  UpdateMealAccess,
} from "../../Services/Actions";
import moment from "moment";
import history from "../../history";

import {
  BottomBarHeightAdjustment,
  BottomBarItems,
  GetStartedButton,
  MultiOptionContainer,
  MultiOptionsContainer,
  SelectAllContainer,
  SelectedItems,
  StyledBottomBar,
} from "./styles";

import ButtonGroup from "../../Components/Buttons/ButtonGroup";
import CalendarHeader from "../../Components/Calendar/CalendarHeader";
import { GA_Event } from "../../Components/GoogleAnalytics";
import GetStarted from "../../Components/GetStarted";
import Loading from "../../Components/Loading";
import PageText from "../../Components/Text/PageText";
import Spacer from "../../Components/Spacer";
import Toggle from "../../Components/Form/Toggle";
import WeeklyView from "../../Components/Calendar/WeeklyView";

import ActivityLevels from "../../Modals/ActivityLevels";
import AddMealMenu from "../../Modals/AddMealMenu";
import ChooseDates from "../../Modals/ChooseDates";
import ClientAndDates from "../../Modals/ClientAndDates";
import Info from "../../Modals/Info";

import { DateSort } from "../../Functions";

class ClientMeals extends React.Component {
  _isMounted = false;

  state = {
    BottomBarHeight: 0,
    ChooseDatesReason: null,
    CurrentActivityLevel: {},
    DayDate: null,
    Loading: true,
    MonthlyView: false,
    SelectedDates: [],
    SelectedMeals: [],
    ShowActivityLevelModal: false,
    ShowAddMealModalMenu: false,
    ShowChooseDatesModal: false,
    ShowCopyMealsModal: false,
    ShowCopyToMealTemplate_LoggedFoodInfoModal: false,
    ShowDeleteMealsModal: false,
    ShowMultiSelect: false,
  };

  componentDidMount() {
    this._isMounted = true;

    var { CalendarDifference, ClientId, StartingDate } = this.props;

    var MonthlyView = false;
    var CustomSettings_LS = localStorage.getItem("CustomSettings");
    if (CustomSettings_LS) {
      CustomSettings_LS = JSON.parse(CustomSettings_LS);
      MonthlyView = CustomSettings_LS.Meals_MonthlyView;
    }

    var StartDate;
    var EndDate;

    if (MonthlyView) {
      StartDate = moment()
        .add(CalendarDifference, "months")
        .startOf("month")
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      EndDate = moment()
        .add(CalendarDifference, "months")
        .endOf("month")
        .endOf("isoWeek")
        .format("YYYY-MM-DD");

      // Calculate WeeklyView dates from StartingDate
      if (StartingDate)
        CalendarDifference = moment(StartingDate).diff(
          moment().startOf("month").format("YYYY-MM-DD"),
          "months"
        );
    } else {
      StartDate = moment()
        .add(CalendarDifference, "weeks")
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      EndDate = moment()
        .add(CalendarDifference, "weeks")
        .endOf("isoWeek")
        .format("YYYY-MM-DD");

      // Calculate WeeklyView dates from StartingDate
      if (StartingDate)
        CalendarDifference = moment(StartingDate)
          .isoWeekday(moment().isoWeekday())
          .diff(moment().format("YYYY-MM-DD"), "weeks");
      // if (StartingDate) CalendarDifference = moment(StartingDate).startOf('isoWeek').diff(moment().startOf('isoWeek'), 'weeks');
    }

    // var StartDate = moment().add(CalendarDifference, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
    // var EndDate = moment().add(CalendarDifference, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');

    this.setState({ MonthlyView }, () => {
      this.props
        .GetClientMeals({ CalendarDifference, ClientId, EndDate, StartDate })
        .then(() => {
          if (this._isMounted) this.setState({ Loading: false });
        });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      !!prevProps.NeedsRefresh !== !!this.props.NeedsRefresh &&
      !!this.props.NeedsRefresh
    )
      this.onUpdateDate({ Direction: 0 });
  }

  onCancelMultiSelect = ({ ShowMultiSelect = false }) => {
    this.setState({ SelectedDates: [], SelectedMeals: [], ShowMultiSelect });
  };

  onChangeToggle = (event) => {
    var Checked = event.target.checked;

    var CustomSettings_LS = localStorage.getItem("CustomSettings");
    if (CustomSettings_LS) CustomSettings_LS = JSON.parse(CustomSettings_LS);
    else
      CustomSettings_LS = {
        Meals_MonthlyView: false,
        Workouts_MonthlyView: false,
      };

    if (Checked) CustomSettings_LS.Meals_MonthlyView = false;
    else CustomSettings_LS.Meals_MonthlyView = true;

    localStorage.setItem("CustomSettings", JSON.stringify(CustomSettings_LS));

    this.setState({ MonthlyView: !Checked }, () =>
      this.onUpdateDate({
        ChangedTo: Checked ? "Weekly" : "Monthly",
        Direction: 0,
      })
    );
  };

  onCopyMeals = (ClientIds, SelectedDates) => {
    this.onToggleShowCopyMealsModal(false);

    // this.setState({ ShowMultiSelect: false });

    this.props
      .CopyMeals({
        AssignedDates: SelectedDates,
        ClientIds,
        MealIds: this.state.SelectedMeals,
      })
      .then(() => {
        this.onUpdateDate({ Direction: 0 });
        this.onCancelMultiSelect({ ShowMultiSelect: true });
      });
  };

  onCopyToMealTemplate = (NeedsCountLoggedFood) => {
    if (this.state.ShowCopyToMealTemplate_LoggedFoodInfoModal)
      this.onToggleShowCopyToMealTemplate_LoggedFoodInfoModal(false);

    if (NeedsCountLoggedFood === false)
      this.props
        .SaveDataInProps({
          WeeklyMealsToTemplateDetails: {
            WeeklyMeals: this.props.ClientMeals,
            MultiSelect_Meals: this.state.SelectedMeals,
          },
        })
        .then(() => history.push("/library/meal-template"));
    else {
      var { SelectedMeals } = this.state;

      var LoggedFoods = [].concat(
        ...this.props.ClientMeals.map(({ dataMeals }) =>
          dataMeals.map(({ DishId, uId }) =>
            (!+DishId && SelectedMeals.indexOf(+uId) !== -1) ? 1 : 0
          )
        )
      );
      var HasLoggedFood = !!+LoggedFoods.reduce((a, b) => a + b, 0);

      if (!HasLoggedFood)
        this.props
          .SaveDataInProps({
            WeeklyMealsToTemplateDetails: {
              WeeklyMeals: this.props.ClientMeals,
              MultiSelect_Meals: this.state.SelectedMeals,
            },
          })
          .then(() => history.push("/library/meal-template"));
      else this.onToggleShowCopyToMealTemplate_LoggedFoodInfoModal(true);
    }
  };

  onDeleteMeals = () => {
    this.onToggleShowDeleteMealsModal(false);

    // this.setState({ ShowMultiSelect: false });

    this.props.DeleteMeals(this.state.SelectedMeals).then(() => {
      this.onUpdateDate({ Direction: 0 });
      this.onCancelMultiSelect({ ShowMultiSelect: true });
    });
  };

  onGetSizeBottomBar = (Height) => {
    this.setState({ BottomBarHeight: Height });
  };

  onMoveMeals = (AssignedDate) => {
    var { MonthlyView } = this.state;

    var EndDate, StartDate;

    if (MonthlyView) {
      StartDate = moment(AssignedDate).startOf("month").format("YYYY-MM-DD");
      EndDate = moment(AssignedDate).endOf("month").format("YYYY-MM-DD");
    } else {
      StartDate = moment(AssignedDate).startOf("isoWeek").format("YYYY-MM-DD");
      EndDate = moment(AssignedDate).endOf("isoWeek").format("YYYY-MM-DD");
    }

    // If AssignedDate is in current week, then delete meals that are moved
    var IsBetween = moment(AssignedDate).isBetween(
      StartDate,
      EndDate,
      null,
      []
    );

    // this.setState({ ShowMultiSelect: false });

    this.props
      .MoveMeals({ AssignedDate, IsBetween, MealIds: this.state.SelectedMeals })
      .then(() => {
        !!IsBetween && this.onUpdateDate({ Direction: 0 });
        this.onCancelMultiSelect({ ShowMultiSelect: true });
      });
  };

  onMultiSelect = (MealId) => {
    var MultiSelect_Index = this.state.SelectedMeals.indexOf(+MealId);

    if (MultiSelect_Index === -1)
      this.setState({ SelectedMeals: [...this.state.SelectedMeals, +MealId] });
    else
      this.setState({
        SelectedMeals: [
          ...this.state.SelectedMeals.slice(0, MultiSelect_Index),
          ...this.state.SelectedMeals.slice(MultiSelect_Index + 1),
        ],
      });
  };

  onSelectAll = () => {
    var SelectedMeals = [].concat.apply(
      [],
      this.props.ClientMeals.map(({ dataMeals }) =>
        dataMeals.map(({ uId }) => +uId)
      )
    );

    this.setState({ SelectedMeals });
  };

  onSelectDate = (SelectedDate) => {
    var { ClientId } = this.props;
    var { MonthlyView } = this.state;

    var CalendarDifference, EndDate, StartDate;

    if (MonthlyView) {
      StartDate = moment(SelectedDate).startOf("month").format("YYYY-MM-DD");
      EndDate = moment(SelectedDate).endOf("month").format("YYYY-MM-DD");

      CalendarDifference = moment(StartDate).diff(
        moment().startOf("month").format("YYYY-MM-DD"),
        "months"
      );
    } else {
      StartDate = moment(SelectedDate).startOf("isoWeek").format("YYYY-MM-DD");
      EndDate = moment(SelectedDate).endOf("isoWeek").format("YYYY-MM-DD");

      CalendarDifference = moment(StartDate)
        .isoWeekday(moment().isoWeekday())
        .diff(moment().format("YYYY-MM-DD"), "weeks");
    }

    this.props.GetClientMeals({
      CalendarDifference,
      ClientId,
      EndDate,
      StartDate,
    });
  };

  onSelectDates = (SelectedDates, ChooseDatesReason) => {
    this.setState(
      {
        SelectedDates: SelectedDates.sort(DateSort),
        ShowChooseDatesModal: false,
      },
      () => {
        if (ChooseDatesReason === "move") this.onMoveMeals(SelectedDates[0]);
      }
    );
  };

  onToggleActivityLevelModal = (
    ShowActivityLevelModal,
    CurrentActivityLevel
  ) => {
    this.setState({ ShowActivityLevelModal, CurrentActivityLevel });
  };

  onToggleAddButton = (ShowAddMealModalMenu, DayDate) => {
    this.setState({ ShowAddMealModalMenu, DayDate: DayDate || null });
  };

  onToggleMultiSelect = (event) => {
    GA_Event({
      Category: "ClientDetail",
      Action: `Toggle Weekly View Multi Select - Meals`,
      Label: `Toggled ${!!event.target.checked ? "On" : "Off"}`,
    });

    this.setState({ ShowMultiSelect: event.target.checked, SelectedMeals: [] });
  };

  onToggleShowChooseDatesModal = (ShowChooseDatesModal, Reason) => {
    // Reason is either "move" or "copy"
    this.setState({ ShowChooseDatesModal, ChooseDatesReason: Reason });
  };

  onToggleShowCopyMealsModal = (ShowCopyMealsModal) => {
    this.setState({ ShowCopyMealsModal });
  };

  onToggleShowCopyToMealTemplate_LoggedFoodInfoModal = (
    ShowCopyToMealTemplate_LoggedFoodInfoModal
  ) => {
    this.setState({ ShowCopyToMealTemplate_LoggedFoodInfoModal });
  };

  onToggleShowDeleteMealsModal = (ShowDeleteMealsModal) => {
    this.setState({ ShowDeleteMealsModal });
  };

  onUpdateDate = ({ ChangedTo, Direction }) => {
    var { CalendarDifference, ClientId } = this.props;
    var { MonthlyView } = this.state;

    var CalculationDate, EndDate, StartDate;
    CalendarDifference += Direction;

    if (ChangedTo) {
      // Was Monthly, Now Weekly ... Get First Day Of Week Of Week Containing 1st Day Of Month
      if (ChangedTo === "Weekly") {
        CalculationDate = moment()
          .add(CalendarDifference, "months")
          .format("YYYY-MM-DD");
        StartDate = moment(CalculationDate)
          .startOf("month")
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        EndDate = moment(CalculationDate)
          .startOf("month")
          .endOf("isoWeek")
          .format("YYYY-MM-DD");

        // Compare to Today isoWeekday()
        CalendarDifference = moment(StartDate)
          .isoWeekday(moment().isoWeekday())
          .diff(moment().format("YYYY-MM-DD"), "weeks");
      }
      // Was Weekly, Now Monthly ... Get First Day Of Month Of Month Container 1st Day Of Week
      else if (ChangedTo === "Monthly") {
        CalculationDate = moment()
          .add(CalendarDifference, "weeks")
          .format("YYYY-MM-DD");
        StartDate = moment(CalculationDate)
          .startOf("isoWeek")
          .startOf("month")
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        EndDate = moment(CalculationDate)
          .startOf("isoWeek")
          .endOf("month")
          .endOf("isoWeek")
          .format("YYYY-MM-DD");

        // Compare to today first day of month
        CalendarDifference = moment(StartDate).diff(
          moment().startOf("month").format("YYYY-MM-DD"),
          "months"
        );
      }
    } else {
      if (MonthlyView) {
        StartDate = moment()
          .add(CalendarDifference, "months")
          .startOf("month")
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        EndDate = moment()
          .add(CalendarDifference, "months")
          .endOf("month")
          .endOf("isoWeek")
          .format("YYYY-MM-DD");
      } else {
        StartDate = moment()
          .add(CalendarDifference, "weeks")
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        EndDate = moment()
          .add(CalendarDifference, "weeks")
          .endOf("isoWeek")
          .format("YYYY-MM-DD");
      }
    }

    this.props.GetClientMeals({
      CalendarDifference,
      ClientId,
      EndDate,
      StartDate,
    });
  };

  onUpdateMealAccess = ({ UpdateType }) => {
    var StartDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
    var EndDate = moment().endOf("isoWeek").format("YYYY-MM-DD");

    this.props.UpdateMealAccess({
      EndDate,
      ReturnDataType: "ClientDetail",
      StartDate,
      UpdateType,
      UserId: this.props.ClientId,
    });
  };

  renderActivityLevelModal = () => {
    var { CurrentActivityLevel, ShowActivityLevelModal } = this.state;

    if (ShowActivityLevelModal) {
      return (
        <ActivityLevels
          ClientId={this.props.ClientId}
          CurrentActivityLevel={CurrentActivityLevel}
          Refresh={() => this.onUpdateDate({ Direction: 0 })}
          Show={ShowActivityLevelModal}
          OnHide={() => this.onToggleActivityLevelModal(false)}
        />
      );
    }
  };

  renderAddMealModalMenu = () => {
    var { ClientDetails } = this.props;
    var { DayDate, ShowAddMealModalMenu } = this.state;

    if (ShowAddMealModalMenu) {
      return (
        <AddMealMenu
          SaveData={{ ClientDetails, DayDate }}
          Show={ShowAddMealModalMenu}
          OnHide={() => this.onToggleAddButton(false)}
        />
      );
    }
  };

  renderBottomBar = () => {
    var { SelectedMeals, ShowMultiSelect } = this.state;

    var MultiSelectLength = SelectedMeals.length;
    if (!ShowMultiSelect) return null;

    var { t } = this.props;
    var { Device } = this.props;

    var Buttons = [
      {
        BackgroundColor: "blue-abel",
        BackgroundColorHover: "blue-abel-hover",
        Border: "none",
        Color: "white",
        ColorHover: "white",
        OnClick: !MultiSelectLength
          ? () => this.onCancelMultiSelect({})
          : this.onCopyToMealTemplate,
        Title: t("MealPlan_Create_meal_template"),
      },
      {
        BackgroundColor: "blue-abel",
        BackgroundColorHover: "blue-abel-hover",
        Border: "none",
        Color: "white",
        ColorHover: "white",
        OnClick: !MultiSelectLength
          ? () => this.onCancelMultiSelect({})
          : () => this.onToggleShowCopyMealsModal(true),
        Title: t("copy"),
      },
      {
        BackgroundColor: "blue-abel",
        BackgroundColorHover: "blue-abel-hover",
        Border: "none",
        Color: "white",
        ColorHover: "white",
        OnClick: !MultiSelectLength
          ? () => this.onCancelMultiSelect({})
          : () => this.onToggleShowChooseDatesModal(true, "move"),
        Title: t("move"),
      },
      {
        BackgroundColor: "red-bittersweet",
        BackgroundColorHover: "red-bittersweet-hover",
        Border: "none",
        Color: "white",
        ColorHover: "white",
        OnClick: !MultiSelectLength
          ? () => this.onCancelMultiSelect({})
          : () => this.onToggleShowDeleteMealsModal(true),
        Title: t("delete_with_first_char_uppercase"),
      },
      {
        BackgroundColor: "white",
        BackgroundColorHover: "white-concrete",
        Border: "none",
        ColorHover: "black",
        OnClick: () => this.onCancelMultiSelect({}),
        Title: t("Cancel_with_first_char_uppercase"),
      },
    ];

    return (
      <StyledBottomBar
        className="StyledBottomBar"
        GetSize={(Height) => this.onGetSizeBottomBar(Height)}
      >
        <BottomBarItems>
          <SelectedItems
            ElementType="span"
            FontColor="white"
            FontFamily="bold"
            FontSize="medium-3"
            NoMargin
            Text={`${MultiSelectLength}`}
          />
          &nbsp;
          <PageText
            ElementType="span"
            FontColor="white"
            FontFamily="medium"
            FontSize="medium-1"
            NoMargin
            Text={t("WorkoutPlanSettings_selected").toLowerCase()}
          />
          <SelectAllContainer>
            <PageText
              FontColor="white"
              FontFamily="bold"
              FontSize="medium-1"
              NoMargin
              OnClick={this.onSelectAll}
              Text={t("select_all")}
            />
          </SelectAllContainer>
        </BottomBarItems>
        <BottomBarItems FullWidth={true}>
          <ButtonGroup
            Buttons={Buttons}
            ContainerWidth={
              Device === "mobile_small" || Device === "mobile" ? "100%" : null
            }
            NotTouching
            OwnRows={Device === "mobile_small" || Device === "mobile"}
          />
        </BottomBarItems>
      </StyledBottomBar>
    );
  };

  renderBottomBarHeightAdjustment = () => {
    if (!this.state.ShowMultiSelect) return null;

    return (
      <BottomBarHeightAdjustment
        className="BottomBarHeightAdjustment"
        Height={this.state.BottomBarHeight}
      />
    );
  };

  renderCopyMealsModal = () => {
    var { ShowCopyMealsModal } = this.state;

    if (ShowCopyMealsModal) {
      var { t } = this.props;

      return (
        <ClientAndDates
          ClientDetails={this.props.ClientDetails}
          OnHide={() => this.onToggleShowCopyMealsModal(false)}
          OnSelect={(ClientIds, SelectedDates) =>
            this.onCopyMeals(ClientIds, SelectedDates)
          }
          Show={ShowCopyMealsModal}
          Title={t("choose_clients_dates_to_copy_to")}
        />
      );
    }
  };

  renderCopyToMealTemplate_LoggedFoodInfoModal = () => {
    var { t } = this.props;
    var { SelectedMeals, ShowCopyToMealTemplate_LoggedFoodInfoModal } =
      this.state;

    var MealDishes = [].concat(
      ...this.props.ClientMeals.map(({ dataMeals }) =>
        dataMeals.map(({ uId }) => (SelectedMeals.indexOf(+uId) !== -1 ? 1 : 0))
      )
    );
    var HasMealDishes = !!+MealDishes.reduce((a, b) => a + b, 0);

    if (ShowCopyToMealTemplate_LoggedFoodInfoModal) {
      return (
        <Info
          BottomButton="submit"
          BottomButtonOnClick={
            HasMealDishes
              ? () => this.onCopyToMealTemplate(false)
              : () =>
                  this.onToggleShowCopyToMealTemplate_LoggedFoodInfoModal(false)
          }
          BottomButtonText={t(
            HasMealDishes ? "MealPlan_Create_meal_template" : "ok"
          )}
          Information={t(
            HasMealDishes
              ? "only_dishes_can_be_copied_to_template_some_selected"
              : "only_dishes_can_be_copied_to_template_none_selected"
          )}
          OnHide={() =>
            this.onToggleShowCopyToMealTemplate_LoggedFoodInfoModal(false)
          }
          Show={ShowCopyToMealTemplate_LoggedFoodInfoModal}
        />
      );
    }
  };

  renderDeleteMealsModal = () => {
    var { t } = this.props;
    var { TryingDeleteMeals } = this.props;
    var { ShowDeleteMealsModal } = this.state;

    if (ShowDeleteMealsModal) {
      return (
        <Info
          BottomButton="submit"
          BottomButtonOnClick={this.onDeleteMeals}
          BottomButtonText={t("delete_with_first_char_uppercase")}
          Information={t("delete_with_warning", {
            Items: t("dishes").toLowerCase(),
          })}
          Loading={TryingDeleteMeals}
          OnHide={() => this.onToggleShowDeleteMealsModal(false)}
          Show={ShowDeleteMealsModal}
        />
      );
    }
  };

  renderMultiOptions = () => {
    var { t } = this.props;
    var { MonthlyView, ShowMultiSelect } = this.state;

    return (
      <MultiOptionsContainer>
        <MultiOptionContainer>
          <PageText
            FontFamily="semibold"
            FontSize="medium-1"
            JustifyContent="center"
            NoMargin
            Text={t("show_week_or_month")}
            TextAlign="center"
          />

          <Toggle
            Checked={!MonthlyView}
            OffLabel={t("monthly")}
            OnChange={this.onChangeToggle}
            OnLabel={t("weekly")}
          />
        </MultiOptionContainer>
        <MultiOptionContainer>
          <PageText
            FontFamily="semibold"
            FontSize="medium-1"
            JustifyContent="center"
            NoMargin
            Text={t("multi_select_and_delete")}
            TextAlign="center"
          />

          <Toggle
            Checked={!!+ShowMultiSelect}
            OffLabel={t("off")}
            OnChange={this.onToggleMultiSelect}
            OnLabel={t("on")}
          />
        </MultiOptionContainer>
      </MultiOptionsContainer>
    );
  };

  renderShowChooseDatesModal = () => {
    var { ShowChooseDatesModal, ChooseDatesReason } = this.state;

    if (ShowChooseDatesModal) {
      return (
        <ChooseDates
          MaxDates={ChooseDatesReason === "move" ? 1 : null}
          OnHide={() => this.onToggleShowChooseDatesModal(false)}
          OnSelectDates={(SelectedDates) =>
            this.onSelectDates(SelectedDates, ChooseDatesReason)
          }
          SelectedDates={this.state.SelectedDates}
          Show={ShowChooseDatesModal}
        />
      );
    }
  };

  render() {
    if (this.state.Loading || this.props.TryingGiveMealAccess)
      return <Loading />;

    var { t } = this.props;
    var {
      ClientId,
      GetStarted_NeedsHeight,
      GetStarted_NeedsWeight,
      MealAccess,
      ShowGetStarted,
      TryingGetClientMeals,
    } = this.props;
    var { MonthlyView, SelectedMeals, ShowMultiSelect } = this.state;

    ClientId = +ClientId;

    return (
      <>
        <div>
          {!MealAccess ? (
            <>
              <Spacer Size="medium" />

              <PageText
                FontFamily="medium"
                FontSize="medium-2"
                JustifyContent="center"
                NoMargin
                Text={t("ptadmin_user_has_no_access")}
                TextAlign="center"
              />

              <Spacer Size="medium" />

              <GetStartedButton
                onClick={() => this.onUpdateMealAccess({ UpdateType: "give" })}
              >
                {t("ptadmin_getstarted_meals")}
              </GetStartedButton>
            </>
          ) : ShowGetStarted ? (
            <GetStarted
              ClientId={ClientId}
              NeedsHeight={!!+GetStarted_NeedsHeight}
              NeedsWeight={!!+GetStarted_NeedsWeight}
              RefreshWeekly={() => this.onUpdateDate({ Direction: 0 })}
              Type="Meals"
            />
          ) : (
            <>
              {this.renderMultiOptions()}

              <Spacer Size="large" />

              <CalendarHeader
                MonthlyView={MonthlyView}
                OnSelectDate={(SelectedDate) => this.onSelectDate(SelectedDate)}
                OnUpdateDate={(Direction) => this.onUpdateDate({ Direction })}
                SectionType="Meals"
              />

              <Spacer Size="medium" />

              <WeeklyView
                ClientId={ClientId}
                Loading={TryingGetClientMeals}
                Meals={this.props.ClientMeals}
                MultiSelect={ShowMultiSelect}
                OnMultiSelectMeals={(MealId) => this.onMultiSelect(MealId)}
                OnToggleActivityLevelModal={(CurrentActivityLevel) =>
                  this.onToggleActivityLevelModal(true, CurrentActivityLevel)
                }
                OnToggleAddButton={(DayDate) =>
                  this.onToggleAddButton(true, DayDate)
                }
                SelectedMeals={SelectedMeals}
                Type="Meals"
              />
            </>
          )}
        </div>

        {this.renderBottomBarHeightAdjustment()}
        {this.renderBottomBar()}

        {this.renderActivityLevelModal()}
        {this.renderAddMealModalMenu()}
        {this.renderCopyMealsModal()}
        {this.renderCopyToMealTemplate_LoggedFoodInfoModal()}
        {this.renderDeleteMealsModal()}
        {this.renderShowChooseDatesModal()}
      </>
    );
  }
}

ClientMeals.propTypes = {
  ClientId: PropTypes.string.isRequired,
  GetStartedCompleted: PropTypes.bool.isRequired,
  NeedsRefresh: PropTypes.bool.isRequired,
  StartingDate: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    Device: state.Window.Device,

    ClientDetails: state.Client.ClientDetails,

    ClientMeals: state.Meals.ClientMeals,
    GetStarted_NeedsHeight: state.Meals.GetStarted_NeedsHeight,
    GetStarted_NeedsWeight: state.Meals.GetStarted_NeedsWeight,
    MealAccess: state.Meals.MealAccess,
    ShowGetStarted: state.Meals.ShowGetStarted,

    CalendarDifference: state.Client.CalendarDifference_Meals,

    TryingGetClientMeals: state.Meals.TryingGetClientMeals,
    TryingGetClientMealsError: state.Meals.TryingGetClientMealsError,
    TryingGiveMealAccess: state.Meals.TryingGiveMealAccess,
    TryingGiveMealAccessError: state.Meals.TryingGiveMealAccessError,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    CopyMeals,
    DeleteMeals,
    GetClientMeals,
    MoveMeals,
    SaveDataInProps,
    UpdateMealAccess,
  })(ClientMeals)
);
